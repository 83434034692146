import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../../../service/sort-list.service';
import { AppComponent } from 'src/app/app.component';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from '@danielmoncada/angular-datetime-picker';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { ImagePopupComponent } from '../../pages/image-popup/image-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY',
  parseInput: 'DD-MM-YYYY',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@Component({
  selector: 'app-dharmshala-booking',
  templateUrl: './dharmshala-booking.component.html',
  styleUrls: ['./dharmshala-booking.component.css'],
  providers: [{ provide: OwlMomentDateTimeModule, useClass: OwlMomentDateTimeModule, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }]

})
export class DharmshalaBookingComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  @Input() darshanType = '';
  public env: any = environment;

  actualDarshanType = '';
  view: CalendarView = CalendarView.Month;
  viewCalendar = true;
  selectedDate = moment(new Date()).format('YYYY-MM-DD');
  activeDayIsOpen: boolean = false;
  form!: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  allDharmshalas: any = {}
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  darshan_type_id = '';
  readDarshanType = '';
  viewCalanderStatus =  true;
  registrationDate = '';
  user_picture_file_name = '';
  id_proof_name = '';
  show_reference_limit_msg = '';
  checkLengthStatus = false;
  timeSlotList:any = [];
  calendarEndDate = moment(new Date()).format('YYYY-MM-DD');
  remainingCountData:any;
  restrictedDates:any = [];
  selectedDarshanObj:any;

  param_dharmshala: any;
  dh_id = false;
  nextDayBooking = false;

  fileData:any = [
    { id: 0,
      user_picture_file_name: '',
      id_proof_name: '',
      imageloading: false,
      idProofloading: false
    }
  ];

  myFilter = (d:any): boolean => {
    this.timeDisable = false;
    const isrestricted =  this.restrictedDates.find((res:any) => (moment(moment(res.booking_date).format('DD-MM-YYYY'), 'DD-MM-YYYY').isSame(d)));
    // return true

    if (isrestricted) {
      return true;
    } else {
      return false;
    }
  }

  allRelations = [];
  roomsDetails = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private appComponent: AppComponent,
    private modalService: NgbModal

  ) {
    this.param_dharmshala = this.route.snapshot.params.dharmshala;
  }

  ngOnInit() {
    this.getAllStates();
    this.getAllDharmshalas();

  

    const max = new Date();
    max.setDate( max.getDate() + 30);
    this.maxDate =  moment(max).format('YYYY-MM-DD');

  }

  CreateForm(data?: any) {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      address: ['', [Validators.required]],
      from_date: [ '', [Validators.required]],
      no_of_days: [1, [Validators.required]],
      dharmshala_id: [data.id, [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      id: [''],
      room_details: this._formBuilder.array([this.getPersonRow()])
    });
  }

  getControls() {
    return (this.form.get('room_details') as FormArray).controls;
  }
  getControlsDetail(i:number) {
    return (this.form.get('room_details') as FormArray).controls[i];
  }


  getPersonRow() {
    return this._formBuilder.group({
      dharmshala_room_id: ['', [Validators.required]],
      no_of_rooms: ['', Validators.required],
      no_of_extra_bed: ['', Validators.required],
      selectedRoomType: [false]
    });
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  numSequence(n: any): Array<number> {
    let arr = [];
    for(let i = 1; i <= n; i++){
      arr.push(i);
    }
    return arr;
  }

  getAllStates() {
    this.apiService.getPublicDataFromServer('/states').subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllDharmshalas() {
    this.apiService.getPublicDataFromServer('/dharmashala_detail/' + this.param_dharmshala).subscribe(
      (res:any) => {
        // this.allStates = res['data'];
          this.allDharmshalas = res.data;
          this.CreateForm(this.allDharmshalas);
          this.getDailyAvailableRooms();
      
      
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getDailyAvailableRooms() {
    let _obj = {
      "dharmshala_id": this.form.value.dharmshala_id,
      "from_date": moment().format('YYYY-MM-DD'),
      "to_date": moment().add(30, 'day').format('YYYY-MM-DD')
    }
    this.apiService.getPublicDataFromServer(`/available_daily_rooms?dharmshala_id=${_obj.dharmshala_id}&from_date=${_obj.from_date}&to_date=${_obj.to_date}`).subscribe(
      (res:any) => {
        // this.allStates = res['data'];
          this.restrictedDates = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }

    getAvailableRooms() {
    if (this.form.value.dharmshala_id != '' && this.form.value.from_date != '' && this.form.value.no_of_days != '') {
      let _obj = {
        "dharmshala_id": this.form.value.dharmshala_id,
        "from_date": moment(this.form.value.from_date).format('YYYY-MM-DD'),
        "no_of_days": this.form.value.no_of_days
      }
      this.apiService.getPublicDataFromServer(`/available_rooms?dharmshala_id=${_obj.dharmshala_id}&from_date=${_obj.from_date}&no_of_days=${_obj.no_of_days}`).subscribe(
        (res: any) => {
          let room_details : any  = <FormArray>this.form.controls['room_details'];

    for (let i = room_details.length; i >=  0; i--) {
      room_details.removeAt(i);
    }
    
      for (let i = 0; i < res.data.length; i++) {
        room_details.push(this._formBuilder.group({
          dharmshala_room_id: [res.data[i].room_type_id, [Validators.required]],
          no_of_rooms: [0, Validators.required],
          no_of_extra_bed: [0, Validators.required],
          selectedRoomType: [false]
        }));
      }
            this.roomsDetails = res.data;
            this.roomsDetails.sort((a: any, b: any) => b.charges - a.charges)

            this.dh_id = true;
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  get formGroups () {
         let room_details : any  = <FormArray>this.form.controls['room_details'];
    // console.log(room_details)

    return room_details;
  }

  saveData() {
    const formObj = this.form.getRawValue();

    formObj.from_date = moment(formObj.from_date).format('YYYY-MM-DD');
    // if(formObj.time_of_visit){
    //   formObj.time_of_visit = moment(formObj.time_of_visit).format('HH:mm');
    // }
    let room_details:any = [];
    formObj.room_details.forEach((val : any) =>{
      if (val.no_of_rooms && val.no_of_rooms > 0){
        room_details.push(val);
      }
    });
    formObj.room_details = room_details;

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/dharmshala_booking', formObj).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          if(res.data && res.data.payment_status == 'Paid' && res.e_pass_url) {
            window.open(res.e_pass_url);
            // window.location.reload();
          } else {
            this.router.navigate(['/dharmshala-payment', res['data']['id']]);
          }
          setTimeout(() => {
            this.appComponent.hideLoader();
          },200);
          this.fileData = [];
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }


  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }


  public remove(i: number) {
    this.form.controls['no_of_persons'].setValue(this.form.value.no_of_persons - 1);
    const contrls = <FormArray>this.form.controls['visitors_basic_details'];
    contrls.removeAt(i);
  }

  addRoom(val:any){
    val.no_of_rooms.setValue(val.no_of_rooms.value + 1);
  }

  substractRoom(val:any){
    val.no_of_rooms.setValue(val.no_of_rooms.value - 1);
  }

  onCheckboxChange(e: any, ind: number){
    console.log(e,ind);
    let room_details : any  = <FormArray>this.form.controls['room_details'];

      for (let i = 0; i < room_details.length; i++) {
        if(i != ind){
          room_details.controls[i].controls.selectedRoomType.setValue(false);
          room_details.controls[i].controls.no_of_rooms.setValue(0);
        }
      } 
     }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onClickImages(keyName:any) {
    const modalRef = this.modalService.open(ImagePopupComponent,
    {
      centered: false, size: 'lg', windowClass: 'my-class', scrollable: false,
      backdrop: true, keyboard: false,
    });
    const imagePopupComponent: ImagePopupComponent = modalRef.componentInstance;
    imagePopupComponent.data = keyName;
  }
}

