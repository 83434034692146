import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { AppComponent } from 'src/app/app.component';
import { Location } from '@angular/common'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertMessagePopoverComponent } from '../../shared/alert-message-popover/alert-message-popover.component';
import { environment } from 'src/environments/environment';

declare var Razorpay: any;

@Component({
  selector: 'app-pujan-booking-payment',
  templateUrl: './pujan-booking-payment.component.html',
  styleUrls: ['./pujan-booking-payment.component.css']
})
export class PujanBookingPaymentComponent implements OnInit {

  env = environment;
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";
  allPaymentTypes = [];
  allPaymentGateways = [];
  payment_type_id:any = 3;
  payment_gateway_id:any = '';
  visitor_id;
  visitorDetails: any = {};
  transaction_no = '';
  transaction_validation_msg = '';
  form!: FormGroup;
  addPayment = false;
  private apiLoadInterval:any = null;
  public showBookingCanceledMsg:any = false;

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private location: Location,
    private modalService: NgbModal,
    private _ngZone: NgZone
  ) {
    this.visitor_id = this.route.snapshot.params.id;

  }

  ngOnInit() {
    this.getAllPaymentTypes();
    this.getAllPaymentGateways();
    this.getVisitor();
  }

  getAllPaymentTypes() {
    this.apiService.getPublicDataFromServer('/payment_types').subscribe(
      (res:any) => {
        this.allPaymentTypes = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllPaymentGateways() {
    this.apiService.getPublicDataFromServer('/payment_gateways').subscribe(
      (res:any) => {
        this.allPaymentGateways = res['data'].filter((val : any) => val.is_enable);
      },
      err => {
        console.log(err);
      }
    );
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  removeSpacesTransaction_no() {
    setTimeout(() => {
      const isWhitespace = (this.transaction_no).trim().length === 0;
      if (isWhitespace) {
        this.transaction_validation_msg = 'Please Enter Valid Transaction No. / Receipt No.'
      } else {
        this.transaction_validation_msg = ''
      }
    },200);

  }

  getVisitor() {
    this.appComponent.showLoader();
    this.apiService.getPublicDataFromServer('/pujan_bookings/' + this.visitor_id).subscribe(
      res => {
        this.showBookingCanceledMsg = false;
        this.visitorDetails = res;
        this.visitorDetails.data.date_of_puja = moment.utc(this.visitorDetails.data.date_of_puja).local().format('DD-MM-YYYY');
        this.appComponent.hideLoader();
        clearTimeout(this.apiLoadInterval);
        if(this.visitorDetails.data && this.visitorDetails.data.payment_status != 'Paid'){
          if(this.visitorDetails && this.visitorDetails.payment_details && this.visitorDetails.payment_details.last_trx_status && (this.visitorDetails.payment_details.last_trx_status.toUpperCase() != 'SUCCESS' || this.visitorDetails.payment_details.last_trx_status.toUpperCase() != 'FAILED')){
            this.alertMessage();
            this.apiLoadInterval = setTimeout(() => {
              this.getVisitor();
            },120000);
          }
        }
      },
      err => {
        this.showBookingCanceledMsg = err.error && err.error.error.length ? err.error.error[0].message : '';
        this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  addPaymentDetail() {
    this.createForm();
    this.addPayment = true;
  }


  alertMessage(){
    const modalRef = this.modalService.open(AlertMessagePopoverComponent,
      {
        centered: true, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const alertMessagePopoverComponent: AlertMessagePopoverComponent = modalRef.componentInstance;
  }



  createForm() {
    const darshan_type_name  = this.visitorDetails && this.visitorDetails.data && this.visitorDetails.data.darshan_type ? this.visitorDetails.data.darshan_type.slug : '';
    const darshan_type = darshan_type_name == 'bhasma_aarti' ? 'Protocol Bhasm Aarti' : darshan_type_name == 'general_bhasmaarti' ? 'Bhasm Aarti' : darshan_type_name == 'general_darshan' ? 'Protocol Darshan' : darshan_type_name == 'sheegra_darshan' ? 'Sheeghra Darshan' : '';
    this.form = this._formBuilder.group({
      payer_first_name: ['', [Validators.required,this.removeSpaces]],
      payer_last_name: ['', [Validators.required,this.removeSpaces]],
      service_id:[this.visitorDetails.data ? this.visitorDetails.data.id :'',[Validators.required]],
      payment_type_id:[this.payment_type_id,[Validators.required]],
      payer_contact:['',[Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      payer_email:['',[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      payer_address:['',[Validators.required,this.removeSpaces]],
      service_type: 'Pujan',
      is_from_mobile: false,
      payment_gateway_id: [this.payment_gateway_id,[Validators.required]]
    });
  }


  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  close() {
    this.addPayment = false;
    this.createForm();
  }

  saveDataOfPayment() {

    this.appComponent.showLoader();
    this.apiService.postPublicDataToServer('/make_payment', this.form.value).subscribe(
      (res:any) => {
        if(res.success) {
          // window.open(res.url);
          if(this.form.value.payment_gateway_id == 2){
            let _data = res.data;
            let options = {
              "key": this.env.rzp_key,
              "amount": (_data.amount * 100),
              "currency": _data.currency,
              "name": "Mahakaleshwar Temple Prabhand Samiti Ujjain",
              "order_id": _data.id,
              "handler": (response: any) => {
                this.verifyPayment(response);
              },
              "prefill": {
                "name": _data.prefill_fields.name,
                "email": _data.prefill_fields.email,
                "contact": _data.prefill_fields.contact
              }
            };
            var rzp1 = new Razorpay(options);

            rzp1.open();
          } else {
            window.location.replace(res.url);
          }
        }
        this.appComponent.hideLoader();
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  viewPass() {
    if(this.visitorDetails && this.visitorDetails.data && this.visitorDetails.data.receipt_url) {
      window.open(this.visitorDetails.data.receipt_url);
    }
    // this.appComponent.showLoader();
    // this.apiService.getDataFromServer('/generate_entry_pass/' + this.visitor_id).subscribe(
    //   (res:any) => {
    //     this.appComponent.hideLoader();
    //     window.open(res['data'][0]);
    //     this.appComponent.showMessageAlert(res['message'], 'success');
    //     this.back();
    //   },
    //   err => {
    //     this.appComponent.hideLoader();
    //     console.log(err);
    //   }
    // );
  }

  saveData() {
    this.appComponent.showLoader();
    let formObj = {
      visitor_id : this.visitor_id,
      payment_type_id : this.payment_type_id,
      amount: this.visitorDetails.payment_details.total_amount,
      trx_id:  this.transaction_no
    };

    this.apiService.postPublicDataToServer('/visitor_payment', formObj).subscribe(
      res => {
        this.apiService.getDataFromServer('/generate_entry_pass/' + this.visitor_id).subscribe(
          (res:any) => {
            this.appComponent.hideLoader();
            window.open(res['data'][0]);
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.back();
          },
          err => {
            this.appComponent.hideLoader();
            console.log(err);
          }
        );
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  verifyPayment(_data : any) {
    this.appComponent.showLoader();
    let formObj = {
      razorpay_payment_id: _data.razorpay_payment_id,
      razorpay_order_id: _data.razorpay_order_id,
      razorpay_signature: _data.razorpay_signature
    };

    this.apiService.postPublicDataToServer('/verify_payment', formObj).subscribe(
      (res : any) => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(res['message'], 'success');
        this._ngZone.run(()=>{
          this.router.navigate(['/payment-status'], {queryParams: {redirect: true , clientTxnId: res['data']['clientTxnId'] }});
        });

      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  back(): void {
    this.location.back();
  }

}

