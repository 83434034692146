import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../../../service/sort-list.service';
import { AppComponent } from 'src/app/app.component';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from '@danielmoncada/angular-datetime-picker';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { CapturePhotoPopoverComponent } from '../../shared/capture-photo-popover/capture-photo-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-ujjain-local-darshanarthi',
  templateUrl: './ujjain-local-darshanarthi.component.html',
  styleUrls: ['./ujjain-local-darshanarthi.component.css']
})
export class UjjainLocalDarshanarthiComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  form: FormGroup;
  aadhar_img : any = {
    aadhaar_back : '',
    aadhaar_front : ''
  };
  allStates = [];
  allCities = [];
  idProofLoading: any;
  keyPressNumbers: any;

  constructor(private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private appComponent: AppComponent,
    private modalService: NgbModal) {

    this.form = this._formBuilder.group({
      name: ['', [Validators.required,this.removeSpaces]],
      age: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      address_line_1: ['', [Validators.required]],
      address_line_2: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      pin_code: ['', [Validators.required]],
      aadhaar_no  : ['', [Validators.required,this.removeSpaces,Validators.pattern('^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$')]],
      aadhaar_front  : ['', [Validators.required]],
      aadhaar_back  : ['', [Validators.required]],
    });
    }

  ngOnInit(): void {
    this.getAllStates();
  }

  getAllStates() {
    this.apiService.getPublicDataFromServer('/states').subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofFileDetails(event:any, img_result: any) {
    let reader = new FileReader();
    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      // this.id_proof_name = file.name;

      reader.onload = (e : any) => {
        this.cd.markForCheck();

         this.aadhar_img[img_result] = e.target.result;
         this.form.controls[img_result].setValue(file);
      };
    }
  }

  saveData() {
    const formObj = this.form.getRawValue();
    const formData = new FormData();
    formData.append('name', formObj.name);
    formData.append('age', formObj.age);
    formData.append('gender', formObj.gender);
    formData.append('aadhaar_no', formObj.aadhaar_no);
    formData.append('mobile_no', formObj.mobile_no);
    formData.append('address_line_1', formObj.address_line_1);
    formData.append('address_line_2', formObj.address_line_2);
    formData.append('city_id', formObj.city_id);
    formData.append('state_id', formObj.state_id);
    formData.append('pin_code', formObj.pin_code);
    formData.append('aadhaar_front', formObj.aadhaar_front);
    formData.append('aadhaar_back', formObj.aadhaar_back);

      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/local_darshanarthis', formData).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          setTimeout(() => {
            this.appComponent.hideLoader();
            this.router.navigate(['/']);
          },200);
          },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
  }

  createSpaceProofNumber(){
      let aadhar_no = this.form.controls['aadhaar_no'].value;
      aadhar_no = aadhar_no.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
       this.form.controls['aadhaar_no'].setValue(aadhar_no);
    }

    removeSpaces(control: AbstractControl) {
      if (control && control.value && !control.value.replace(/\s/g, '').length) {
        control.setValue('');
      }
      return null;
    }

}
