import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-capture-photo-popover',
  templateUrl: './capture-photo-popover.component.html',
  styleUrls: ['./capture-photo-popover.component.css']
})
export class CapturePhotoPopoverComponent implements OnInit {

  WIDTH = 440;
  HEIGHT = 340;
  @ViewChild('video', {static: false})
  public video!: ElementRef;
  headerName:any;
  @ViewChild("canvas", {static: false})
  public canvas!: ElementRef;
  captures: string[] = [];
  error: any;
  isCaptured: boolean = false;
  data:any;
  holdStreamData:any;

  constructor(
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit() {
    
  }

  async ngAfterViewInit() {
    if(!this.data) {
      await this.setupDevices();
    }
  }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        this.holdStreamData = stream;
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = "You have no output video device";
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  async capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
    // const stream = await navigator.mediaDevices.getUserMedia({
    //   video: false
    // });
    // this.video.nativeElement.srcObject = stream;
    this.isCaptured = true;
    this.clearStream();
    this.activeModal.close(this.captures[0]);

  }
  editImage() {
    this.data = '';
    this.ngAfterViewInit();
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  private clearStream() {
    if(this.holdStreamData) {
      const stream = this.holdStreamData;
      const tracks = stream.getTracks();
      tracks[0].stop();
      tracks.forEach((track:any) => track.stop());
      this.video.nativeElement.srcObject = null;
    }
  }

  public closeModal() {
    this.clearStream();
    this.activeModal.close('');
  }

}

