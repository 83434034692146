import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AppComponent } from 'src/app/app.component';
import { SortListService } from 'src/app/service/sort-list.service';

@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit {
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  
  form!: FormGroup;
  formMode = 'Add';
  public suggestion_types = [];
  public suggestionList = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private sortListService: SortListService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getAllSuggestionTypes();
    this.getAllSuggestion();
  }


  createForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required,this.removeSpaces]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      suggestion_type_id: ['', [Validators.required]],
      suggestion: ['', [Validators.required]],
      address:  ['', [Validators.required]],
    });
  }

  

 
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }



  getAllSuggestionTypes() {
    this.apiService.getPublicDataFromServer('/suggestion_types').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'title').then(res => {
          this.suggestion_types = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllSuggestion() {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getPublicDataFromServer(`/suggestions?page=1&perpage=10000000&date=${from_date}&end_date=${to_date}`).subscribe(
      (res:any) => {
        this.appComponent.hideLoader();
        this.suggestionList = res.data;
      },
      err => {
        this.appComponent.hideLoader();
        console.log(err);
      }
    );
  }


  saveData() {
    const formObj = this.form.getRawValue();
    this.appComponent.showLoader();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/add_suggestion', formObj).subscribe(
        (res:any) => {
          this.createForm();
          this.getAllSuggestion();
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.appComponent.hideLoader();
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }



  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  close() {
    this.router.navigate(['/home']);

  }

}
