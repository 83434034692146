import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortListService {

  constructor() { }

  async sortList(data:any, key:any) {
   return await data.sort((a:any, b:any) =>  a[key].localeCompare(b[key], 'en'))
  }
}
