import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-bhasmarti-request-success',
  templateUrl: './bhasmarti-request-success.component.html',
  styleUrls: ['./bhasmarti-request-success.component.css']
})
export class BhasmartiRequestSuccessComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  visitor_id = '';

  public visitorsDetail:any = {
  };
  

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.appComponent.showLoader();
    this.visitor_id = this.route.snapshot.params.id;
    this.getVisitorsInfo(this.visitor_id);
    
  }

 

  getVisitorsInfo(_id: any) {
    this.apiService.getPublicDataFromServer(`/bhasmarti_request_detail?request_id=${_id}`).subscribe(
      (res:any) => {
        this.appComponent.hideLoader();
        this.visitorsDetail = res['data'];
         
      },
      err => {
        console.log(err);
      }
    );
  }

}

