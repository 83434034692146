
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../../../service/sort-list.service';
import { AppComponent } from 'src/app/app.component';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  TemplateRef,
  OnDestroy,
  Injectable,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
  CalendarUtils
} from 'angular-calendar';
import { subWeeks, startOfMonth, endOfMonth, addWeeks, startOfWeek } from 'date-fns';
import { GetMonthViewArgs, MonthView } from 'calendar-utils';
import { Subject } from 'rxjs';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from '@danielmoncada/angular-datetime-picker';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { CapturePhotoPopoverComponent } from '../../shared/capture-photo-popover/capture-photo-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from "@angular/common";

import enIN from "@angular/common/locales/hi";

registerLocaleData(enIN);


export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY',
  parseInput: 'DD-MM-YYYY',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Injectable()
export class MyCalendarUtils extends CalendarUtils {
  // getMonthView(args: GetMonthViewArgs): MonthView {
  //   args.viewEnd = addWeeks(endOfMonth(args.viewDate), 4);
  //   return super.getMonthView(args);
  getMonthView(args: GetMonthViewArgs): MonthView {
    args.viewStart = startOfWeek(args.viewDate, {weekStartsOn : 1});
    args.viewEnd = addWeeks(args.viewDate, 2);
    return super.getMonthView(args);
  }
}

@Component({
  selector: 'app-bhasmarti-request',
  templateUrl: './bhasmarti-request.component.html',
  styleUrls: ['./bhasmarti-request.component.css']
})
export class BhasmartiRequestComponent implements OnInit {


  @Input() darshanType = '';
  public env: any = environment;

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  currentDate = new Date();
  actualDarshanType = '';
  view: CalendarView = CalendarView.Month;
  viewCalendar = true;
  selectedDate = moment(new Date()).format('YYYY-MM-DD');
  CalendarView = CalendarView;
  refresh = new Subject<void>();
  activeDayIsOpen: boolean = false;
  form: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  minDate: any = moment(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1)).format('YYYY-MM-DD');
  viewDate: Date = new Date(this.minDate);

  maxDate: any = '';
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  user_roles:any;
  darshan_type_id = '';
  readDarshanType = '';
  viewCalanderStatus =  true;
  registrationDate = '';
  user_picture_file_name = '';
  id_proof_name = '';
  show_reference_limit_msg = '';
  checkLengthStatus = false;
  timeSlotList:any = [];
  calendarEndDate = moment(new Date()).format('YYYY-MM-DD');
  restrictedDates:any = [];

  fileData:any = [
    { id: 0,
      user_picture_file_name: '',
      id_proof_name: '',
      imageloading: false,
      idProofloading: false
    }
  ];

  myFilter = (d:any): boolean => {
    this.timeDisable = false;
    const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type.slug == 'general_bhasmaarti' && moment(moment(res.date).format('DD-MM-YYYY'), 'DD-MM-YYYY').isSame(d)));
    // return true

    if (isrestricted) {
      return false;
    } else {
      return true;
    }
  }

  allRelations = [];
  recaptchaVerified = false;
  modalResultSelection = true;server_datetime: any;
  bhasmaartiCountInterval:any;


  constructor(
    private _formBuilder: FormBuilder,
    public apiService : ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private appComponent: AppComponent,
    private modalService: NgbModal

  ) {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      request_for_date: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      no_of_persons: ['', [Validators.required]],
      // time_of_visit: ['' ? [Validators.required] : []],
      id: [''],
      aadhar_number: ['', [Validators.required,this.removeSpaces, Validators.pattern('^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$')]],
      visitors_basic_details: this._formBuilder.array([this.getPersonRow()])
    });
  }



  ngOnInit() {
    this.actualDarshanType = this.darshanType;
    const date = new Date();
    const re = /-/gi;
    const newDarshanType = this.darshanType.replace(re, "_");
    this.readDarshanType = newDarshanType;
    // if(newDarshanType == 'bhasma_aarti') {
    // this.minDate = moment('2024-07-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
    // } else {
    //   date.setDate( date.getDate());
    //   this.minDate = moment('2024-07-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
    // }
    this.getAllStates();
    this.getAllRelationsList();
    this.getRestrictedDates();

  }

  getControls() {
    return (this.form.get('visitors_basic_details') as FormArray).controls;
  }
  getControlsDetail(i:number) {
    return (this.form.get('visitors_basic_details') as FormArray).controls[i];
  }


  calendarDate(event:any) {
    this.calendarEndDate = event.period.end;
  }

  resolvedRecaptcha(captchaResponse: string, captchaRef: any) {
    this.recaptchaVerified = false;
    this.apiService.postPublicDataToServer('/verify_recaptcha',{token : captchaResponse}).subscribe(
      (res:any) => {
       if(res.success){
        this.recaptchaVerified = true;
       }else {
        captchaRef.reset();
       }
      },
      err => {
        console.log(err);
        captchaRef.reset();
      }
    );
  }

  onPickerOpen(e:any){
    const darshan_type =  'general_bhasmaarti';

    const filteredDates =  this.restrictedDates.filter((res:any) => (res.darshan_type.slug == darshan_type));


    for (let i = 0; i < filteredDates.length; i++) {
      let date  = moment(filteredDates[i].date).format('MMMM D, YYYY');
      let classname:any = document.querySelector(`[aria-label="${date}"].owl-dt-calendar-cell-disabled`);

      if(classname) {
        let element = classname.getElementsByTagName('span');


        let span = document.createElement('span');
        let content = document.createTextNode('Blocked');
        span.appendChild(content);

        if(element[0].querySelector('span')) {
          element[0].querySelector('span').remove();
        }
        element[0].appendChild(span);
      }
    }
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.selectedDate = moment(date).format('DD-MM-YYYY');
      if(moment(date).format('YYYY-MM-DD') < moment(this.minDate,'YYYY-MM-DD').format('YYYY-MM-DD')){
        this.appComponent.showMessageAlert('This Date is not open for Bhasmarti Reuest', 'error');
      } else {
          clearInterval(this.bhasmaartiCountInterval);
          this.checkRestrictedDates(date);
        } 
    document.body.addEventListener('click', () => setTimeout(() => {                           // <<<---using ()=> syntax
      this.onPickerOpen('');
    }, 1000), true);

  }


  showRestrictedDateMessage(date:any){
    const activeDate =  moment(date).format('YYYY-MM-DD');
    const currentDate  =  this.minDate;
    // if(activeDate  currentDate){
      const darshan_type =  'general_bhasmaarti';
      const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type.slug == darshan_type && moment(res.date).format('YYYY-MM-DD') == activeDate));
      let max = new Date(this.minDate);
      max = new Date(max.getFullYear(),max.getMonth() + 3, 0);
      this.maxDate =  moment(max).format('YYYY-MM-DD');
      if(isrestricted){
        return 'The date is blocked for Bhasm Aarti request.';
      }else {
        return ''
      }
    // } else {
    //   return '';
    // }
  }
  showNotOpenRestrictedDateMessage(date:any){
    const activeDate =  moment(date).format('YYYY-MM-DD');

    const minDate  =  this.minDate;
    if(activeDate < minDate || new Date(this.maxDate) < new Date(date)){
        return 'The date is not open for Bhasm Aarti request.'
      }else {
        return ''
      }
  }

  checkRestrictedDates(date:any) {
    const darshan_type =  'general_bhasmaarti';
    const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type.slug == darshan_type && moment(res.date).format('DD-MM-YYYY') == this.selectedDate));
    let max = new Date(this.minDate);

     max = new Date(max.getFullYear(),max.getMonth() + 3, 0);
    this.maxDate =  moment(max).format('YYYY-MM-DD');
    if(new Date(max) > new Date(date)){
      if(!isrestricted){
        if (new Date(date) > new Date()  || moment(new Date).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY')) {
            this.getRegistrationDate(this.selectedDate);
            // this.getRegistrationDate(this.selectedDate);
        } else {
          this.appComponent.showMessageAlert('Please Select Today or Future Date', 'error');
        }
      } else {
        const msg = 'The selected date is blocked for Bhasm Aarti request.'
        this.appComponent.showMessageAlert(msg, 'error');
      }
    }else {
      const msg = 'The date is not open for Bhasm Aarti request.'
      this.appComponent.showMessageAlert(msg, 'error');
    }

  }



  getRestrictedDates() {
    this.apiService.getPublicDataFromServer('/restricted_dates').subscribe(
      (res:any) => {
        this.restrictedDates = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }



  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  onClickCapturePhoto(index:any,key:any,controlName:string) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[index]['controls'];

    let document_id = child_cntrl[controlName];
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = null;
    capturePhotoPopoverComponent.headerName = key == 'user_picture_file_name' ? 'Capture Photo' : 'Capture ID Proof Photo';


    modalRef.result.then((result:any) => {
      if (result) {
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });
          const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
          let file_name = controlArray.controls[index].get('name').value;
          const imageFile = new File([blob], `${file_name}-image`, { type: 'image/png' });
          const obj = {
            id: index,
            user_picture_file_name: '',
            id_proof_name: '',
            imageloading: key != 'id_proof_name' ? true : false,
            idProofloading: key == 'id_proof_name' ? true : false
          }
          const isExits =  this.fileData.find((res:any)  =>  res.id == index);
          if(isExits) {
            // isExits.user_picture_file_name = '';
            isExits.imageloading = key != 'id_proof_name' ? true : false;
            isExits.idProofloading = key == 'id_proof_name' ? true : false;
          } else {
            this.fileData.push(obj);

          }
          this.postDocumentToServer(imageFile, document_id,key,index);
        });

      }
    });
  }


  createForm(data?:any) {
    const date = new Date();
    let futureTime:any = new Date(date.getTime() + 15*60000);
    futureTime = moment(futureTime, 'hh:mm').format();

    this.form = this._formBuilder.group({
      first_name: [data && data.first_name ? data.first_name : '', [Validators.required,this.removeSpaces]],
      last_name: [data && data.last_name ? data.last_name : '', [Validators.required,this.removeSpaces]],
      mobile_no: [data && data.mobile_no ? data.mobile_no : '', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      request_for_date: [data && data.request_for_date ? data.request_for_date : '', [Validators.required]],
      state_id: [data && data.state_id ? data.state_id: '', this.actualDarshanType !=  'sheegra-darshan' ? [Validators.required] : ''],
      city_id: [data && data.city_id ? data.city_id: '', this.actualDarshanType !=  'sheegra-darshan' ? [Validators.required] : ''],
      no_of_persons: [data && data.no_of_persons ? data.no_of_persons: '1', [Validators.required]],
      // time_of_visit: [futureTime],
      aadhar_number : [data && data.aadhar_number ? data.aadhar_number : '', [Validators.required,this.removeSpaces, Validators.pattern('^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$')]],
      id: [data && data.id ? data.id: ''],
      visitors_basic_details: this._formBuilder.array([this.getPersonRow()])
    });
  }


  getPersonRow() {
    return this._formBuilder.group({
      name: ['', [Validators.required]],
      gender: ['', Validators.required],
      relationship_id: [9, Validators.required],
      aadhar_number  : ['', [Validators.required,this.removeSpaces, Validators.pattern('^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$')]],
      image_url: ['',[Validators.required]],
      aadhar_url: ['', [Validators.required]]
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }


  onChangeName() {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      controlArray.controls[0].get('name').setValue(`${this.form.value.first_name} ${this.form.value.last_name}`);
    }
  }
  
  getRegistrationDate(event:any) {
    this.viewCalendar = false;
    this.registrationDate = event;
    const darshanType = this.darshanType;
    const re = /-/gi;
    const newDarshanType = darshanType.replace(re, "_");
    this.createForm({
      request_for_date: moment(this.registrationDate ? this.registrationDate : new Date(),'DD-MM-YYYY').format()
    });
        this.viewCalanderStatus = false;
  }


  getAllVisitorTypes() {
    this.apiService.getPublicDataFromServer('/visitor_types').subscribe(
      (res:any) => {
        this.visitor_type = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllStates() {
    this.apiService.getPublicDataFromServer('/states').subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllRelationsList() {
    this.apiService.getPublicDataFromServer('/relations').subscribe(
      (res:any) => {
        this.allRelations = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  saveData() {
    const formObj = this.form.getRawValue();

    formObj.request_for_date = moment(formObj.request_for_date).format('YYYY-MM-DD');
   
    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/bhasmarti_requests', formObj).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.user_picture_file_name = '';
          this.id_proof_name = '';
          
            this.router.navigate(['/bhasmarti-request-success', res.data.id]);
          setTimeout(() => {
            this.appComponent.hideLoader();
          },200);
          this.fileData = [];
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }



  onChangeDate(content: any) {
    
  }


  onPersonCountChange(){

    let visitors_basic_details : any  = <FormArray>this.form.controls['visitors_basic_details'];
    if(this.form.value.no_of_persons >= 1) {
      if(visitors_basic_details.length < this.form.value.no_of_persons) {
        let persons = this.form.value.no_of_persons - visitors_basic_details.length;
        for (let i = 0; i < persons; i++) {
          visitors_basic_details.push(this._formBuilder.group({
            name: ['', [Validators.required]],
            gender: ['', Validators.required],
            relationship_id: ['', Validators.required],
            aadhar_number: ['', [Validators.required,this.removeSpaces, Validators.pattern('^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$')]],
            image_url: ['',Validators.required],
            aadhar_url: ['', Validators.required]
          }));
        }
      } else {
        let persons = visitors_basic_details.length;
        for (let i = persons - 1; i >  this.form.value.no_of_persons - 1; i--) {
          visitors_basic_details.removeAt(i);
        }
      }
    }
  }

  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  onChangePeopleProofNumber(i:any) {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('aadhar_number') && controlArray.controls[i].get('aadhar_number').value && !controlArray.controls[i].get('aadhar_number').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('aadhar_number').setValue('');
    }

  }

  createSpaceProofNumber(i:any){
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
        let id_proof_number =  controlArray.controls[i].get('aadhar_number').value;
        id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        controlArray.controls[i].get('aadhar_number').setValue(id_proof_number);
    }
    this.form.get('aadhar_number')?.setValue(controlArray.controls[0].get('aadhar_number').value);
  }



  onChangePeopleName(i:any) {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('name') && controlArray.controls[i].get('name').value && !controlArray.controls[i].get('name').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('name').setValue('');
    }
  }

  public remove(i: number) {
    this.form.controls['no_of_persons'].setValue(this.form.value.no_of_persons - 1);
    const contrls = <FormArray>this.form.controls['visitors_basic_details'];
      contrls.removeAt(i);
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFileChange(event:any, i:any) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[i]['controls'];

    let document_id = child_cntrl['image_url'];

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const obj = {
        id: i,
        user_picture_file_name: '',
        id_proof_name: '',
        imageloading: true,
        idProofloading: false
      }
      const isExits =  this.fileData.find((res:any)  =>  res.id == i);
      if(isExits) {
        isExits.user_picture_file_name = '';
        isExits.imageloading = true;
      } else {
        this.fileData.push(obj);

      }
      reader.readAsDataURL(file);
      this.postDocumentToServer(event.target.files[0], document_id,'user_picture_file_name', i);

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  checkFileData(i:any,key:any) {
    const isExist = this.fileData.find((res:any) => res.id == i);
    return isExist && isExist[key] ? isExist[key] : '';
  }

  getIdProofFileDetails(event:any, i:any) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[i]['controls'];

    let document_id = child_cntrl['aadhar_url'];
    // let entity_tp_id = this.form.controls['entity_id'].value;

    let reader = new FileReader();

    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      const obj = {
        id: i,
        user_picture_file_name: '',
        id_proof_name: '',
        imageloading: false,
        idProofloading: true
      }
      const isExits =  this.fileData.find((res:any)  =>  res.id == i);
      if(isExits) {
        isExits.id_proof_name = '';
        isExits.idProofloading = true;
      } else {
        this.fileData.push(obj);

      }

      this.postDocumentToServer(event.target['files'][0], document_id,'id_proof_name',i);

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  postDocumentToServer(file_data:any, document_id:any,key:any,index:any) {
    const formData = new FormData();
    formData.append('file', file_data);


    this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
      let responsedata :  any = res;
        if(responsedata.data){
          document_id.setValue(responsedata.data);
        }
        this.fileData[index][key] = responsedata.data;
        this.fileData[index].imageloading =  false;
        this.fileData[index].idProofloading =  false;

    }, (error) => {
      this.fileData[index].imageloading =  false;
      this.fileData[index].idProofloading =  false;
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(error.error.error[0].message, 'error');
        console.log(error);
    });
  }


  ngOnDestroy(){
    clearInterval(this.bhasmaartiCountInterval);
  }

}

