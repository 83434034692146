import {Component, OnInit, HostListener, Inject, AfterViewInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import navigation from '../../../data/navigation.json';
import {TranslateService} from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit , AfterViewInit{

  constructor(@Inject(DOCUMENT) _document:any,
              public translate: TranslateService,
              public apiService : ApiService
  ) { }
  public navigation = navigation;
  // Navigation
  navMethod: boolean = true;
  toggleNav() {
    this.navMethod = !this.navMethod;
  }
  // Mobile
  open: boolean = false;
  trigger(item: { open: boolean; }){
    item.open = !item.open;
  }
  // Sticky Header
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(_e: any) {
    if (window.pageYOffset > 110) {
      let element = <HTMLElement>document.getElementById('sticky-header');
      element.classList.add('sticky');
    } else {
      let element = <HTMLElement>document.getElementById('sticky-header');
      element.classList.remove('sticky');
    }
  }
  // Add class on resize and onload window
  visible: boolean = false;
  breakpoint: number = 991;
  public innerWidth: any;
  detectHeader() {
    this.innerWidth = window.innerWidth;
    this.visible = this.innerWidth >= this.breakpoint;
  }

  ngOnInit(): void {
    this.detectHeader();
  }

  ngAfterViewInit() {
    let ref: any = document.getElementById('google_translate_element');
    let v = document.createElement("script");
    v.type = "text/javascript";
    v.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element'); } ";
    ref.appendChild(v);
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    ref.appendChild(s);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
