import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-filter-event',
  templateUrl: './filter-event.component.html',
  styleUrls: ['./filter-event.component.css']
})
export class FilterEventComponent implements OnInit {

  constructor(private apiService : ApiService) { }
  allNewsEvents = [];

  ngOnInit(): void {
    this.getAllNewsEvents();
  }

  getAllNewsEvents(){
    this.apiService.getPublicDataFromServer(`/news_and_events`).subscribe(
      (res:any) => {
        this.allNewsEvents = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  openImageNewTab(doc:any){
    // if (doc) {
      window.open(doc);
    // } else {
    //   window.open(img);
    // }

  }

}
