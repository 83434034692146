import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../../../service/sort-list.service';
import { AppComponent } from 'src/app/app.component';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from '@danielmoncada/angular-datetime-picker';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { CapturePhotoPopoverComponent } from '../../shared/capture-photo-popover/capture-photo-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY',
  parseInput: 'DD-MM-YYYY',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@Component({
  selector: 'app-pujan-booking',
  templateUrl: './pujan-booking.component.html',
  styleUrls: ['./pujan-booking.component.css']
})
export class PujanBookingComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  @Input() darshanType = '';
  public env: any = environment;

  actualDarshanType = '';
  view: CalendarView = CalendarView.Month;
  viewCalendar = true;
  selectedDate = moment(new Date()).format('YYYY-MM-DD');
  activeDayIsOpen: boolean = false;
  form!: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  allPujas = [];
  minDate = moment(new Date()).add(1, 'd').format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  darshan_type_id = '';

  user_picture_file_name = '';
  id_proof_name = '';
  checkLengthStatus = false;
  restrictedDates:any = [];
  imageUrlLoading = false;
  idProofLoading = false;


  myFilter = (d:any): boolean => {
    this.timeDisable = false;
    const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type_id == this.darshan_type_id && moment(moment(res.date).format('DD-MM-YYYY'), 'DD-MM-YYYY').isSame(d)));
    // return true

    if (isrestricted) {
      return false;
    } else {
      return true;
    }
  }


  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private appComponent: AppComponent,
    private modalService: NgbModal

  ) {}


  ngOnInit(): void {
    this.CreateForm();
    this.getAllStates();
    this.getAllPujas();
    this.getIdProofTypesList();
  }

  CreateForm() {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      father_name: ['', [Validators.required,this.removeSpaces]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      date_of_puja: [ '', [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      puja_type_id: ['', [Validators.required]],
      id_proof_type_id : ['', Validators.required],
      id_proof_number  : ['', [Validators.required,this.removeSpaces]],
      image_url: ['', [Validators.required]],
      id_proof_image_url: ['', [Validators.required]],
      id: [''],
    });
  }

  getAllStates() {
    this.apiService.getPublicDataFromServer('/states').subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllPujas() {
    this.apiService.getPublicDataFromServer('/pujas').subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'puja_name').then(res => {
          this.allPujas = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofTypesList() {
    this.apiService.getPublicDataFromServer('/id_proof_types').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.id_proof_type_list = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  onIdProofTypeChange() {
    this.checkLengthStatus = false;

    let aadharCardRgx = '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$';
    let drivingLicenseRgx = '^([A-Z]{2})(\\d{2}|\\d{3})[a-zA-Z]{0,1}-(\\d{4})-(\\d{7})$';
    let electionCommissionIDRgx = '^([a-zA-Z]){3}([0-9]){7}?$';
    let passportRgx = '^[A-Z][0-9]{8}$';
    this.form.controls['id_proof_number'].setValue('');

    if(this.form.controls['id_proof_type_id'].value == 1){
      this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(aadharCardRgx)]);
      this.checkLengthStatus = true;
    }
    if(this.form.controls['id_proof_type_id'].value == 2){
      this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(passportRgx)]);
    }
    if(this.form.controls['id_proof_type_id'].value == 3){
      this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(drivingLicenseRgx)]);
    }
    if(this.form.controls['id_proof_type_id'].value == 4){
      this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(electionCommissionIDRgx)]);
    }
    this.form.controls['id_proof_number'].updateValueAndValidity();
  }

  createSpaceProofNumber(){
    if(this.form.controls['id_proof_type_id'].value == 1) {
      let id_proof_number = this.form.controls['id_proof_number'].value;
      id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      this.form.controls['id_proof_number'].setValue(id_proof_number);
    }
  }

  onFileChange(event:any) {
    this.imageUrlLoading = true;

    let document_id = this.form.value.image_url;
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      // this.user_picture_file_name = file.name;

      this.postDocumentToServer(event.target.files[0], document_id , 'image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }



  getIdProofFileDetails(event:any) {
    this.idProofLoading = true;
    let document_id = this.form.value.id_proof_image_url;

    let reader = new FileReader();

    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      // this.id_proof_name = file.name;

      this.postDocumentToServer(event.target['files'][0], document_id, 'id_proof_image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  onClickCapturePhoto(keyName:any) {
    let document_id = this.form.value.image_url;
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = null;
    capturePhotoPopoverComponent.headerName = keyName == 'image_url' ? 'Capture Photo' : 'Capture ID Proof Photo';


    modalRef.result.then((result:any) => {
      if (result) {
        if(keyName == 'id_proof_image_url') {
          this.idProofLoading = true;
        } else {
          this.imageUrlLoading = true;
        }
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });

          const imageFile = new File([blob], `${this.form.value.first_name}-image`, { type: 'image/png' });

          this.postDocumentToServer(imageFile, document_id, keyName);
        });

      }
    });
  }


  postDocumentToServer(file_data:any, document_id:any,keyName?:any) {
    const formData = new FormData();
    formData.append('file', file_data);

    this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
      let responsedata :  any = res;
      if(responsedata && responsedata.status_code == 200){
        this.form.controls[keyName].setValue(responsedata.data);
        this.user_picture_file_name = keyName == 'image_url' ? responsedata.data : this.user_picture_file_name;
        this.imageUrlLoading = false;
        this.id_proof_name = keyName != 'image_url' ? responsedata.data : this.id_proof_name;
        this.idProofLoading = false;
      }
    }, (error) => {

    });
  }

  saveData() {
    const formObj = this.form.getRawValue();

    formObj.date_of_puja = moment(formObj.date_of_puja).format('YYYY-MM-DD');


    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/pujan_bookings', formObj).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          if(res.data && res.data.payment_status == 'Paid' && res.receipt_url) {
            window.open(res.receipt_url);
            // window.location.reload();
          } else {
            this.router.navigate(['/pujan-payment', res['data']['id']]);
          }
          setTimeout(() => {
            this.appComponent.hideLoader();
          },200);
          this.id_proof_name = '';
          this.user_picture_file_name = '';
          },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
}
