import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DonateMoneyOnlineComponent } from './components/pages/donate-money-online/donate-money-online.component';
import { DownloadEPassComponent } from './components/pages/download-e-pass/download-e-pass.component';
import { ManagementCommitteComponent } from './components/pages/management-committe/management-committe.component';
import { MoneyDonationRecivedComponent } from './components/pages/money-donation-recived/money-donation-recived.component';
import { PaymentDetailComponent } from './components/pages/payment-detail/payment-detail.component';
import { PaymentStatusComponent } from './components/pages/payment-status/payment-status.component';
import { DharmshalaBookingPaymentComponent } from './components/pages/dharmshala-booking-payment/dharmshala-booking-payment.component';
import { PrasadOrderComponent } from './components/pages/prasad-order/prasad-order.component';
import { SuggestionComponent } from './components/pages/suggestion/suggestion.component';
import { TenderComponent } from './components/pages/tender/tender.component';
import {DharmshalaBookingComponent} from './components/pages/dharmshala-booking/dharmshala-booking.component';
import { DharmshalaListComponent } from './components/pages/dharmshala-list/dharmshala-list.component';
import {FeedbackFormComponent} from './components/pages/feedback-form/feedback-form.component';
import {PujanBookingComponent} from './components/pages/pujan-booking/pujan-booking.component';
import {PujanBookingPaymentComponent} from './components/pages/pujan-booking-payment/pujan-booking-payment.component';
import { JalabhishekBookingComponent } from './components/pages/jalabhishek-booking/jalabhishek-booking.component';
import { UjjainLocalDarshanarthiComponent } from './components/pages/ujjain-local-darshanarthi/ujjain-local-darshanarthi.component';
import { BhasmartiRequestComponent } from './components/pages/bhasmarti-request/bhasmarti-request.component';
import { BhasmartiRequestSuccessComponent } from './components/pages/bhasmarti-request-success/bhasmarti-request-success.component';
import { NagchandreshwarDarshanComponent } from './components/pages/nagchandreshwar-darshan/nagchandreshwar-darshan.component';

const routes: Routes = [
  // Home
  { path: '', pathMatch: 'full', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: 'Home' } },
  // {
  //   path: 'registration-form', component: RegistrationFormComponent
  // },
  { path: 'bhasmarti', loadChildren: () => import('./components/pages/bhasmarti/bhasmarti.module').then(m => m.BhasmartiModule), data: { breadcrumb: 'BhasmAarti' } },
  // { path: 'general-darshan', loadChildren: () => import('./components/pages/general-darshan/general-darshan.module').then(m => m.GeneralDarshanModule), data: { breadcrumb: 'GeneralDarshanTicket' } },
  { path: 'payment/:id', component: PaymentDetailComponent, data: { breadcrumb: 'PaymentDetails' } },
  { path: 'dharmshala-payment/:id', component: DharmshalaBookingPaymentComponent, data: { breadcrumb: 'DharmshalaPaymentDetails' } },
  { path: 'payment-status', component: PaymentStatusComponent, data: { breadcrumb: 'PaymentStatus' } },
  { path: 'donate-money-online', component: DonateMoneyOnlineComponent, data: { breadcrumb: 'DonateMoney' } },
  { path: 'suggestion', component: SuggestionComponent, data: { breadcrumb: 'suggestion' } },
  { path: 'tender', component: TenderComponent, data: { breadcrumb: 'tender' } },
  { path: 'download-e-pass', component: DownloadEPassComponent, data: { breadcrumb: 'downloadEPass' } },
  { path: 'management-committe', component: ManagementCommitteComponent, data: { breadcrumb: 'managementCommitte' } },
  { path: 'donate-money-received', component: MoneyDonationRecivedComponent, data: { breadcrumb: 'MoneyDonationReceived' } },
  { path: 'prasad-order', component: PrasadOrderComponent, data: { breadcrumb: 'prasadOrder' } },
  { path: 'dharmshala-booking/:dharmshala', component: DharmshalaBookingComponent, data: { breadcrumb: 'DharmshalaBooking' } },
  { path: 'dharmshala-list', component: DharmshalaListComponent, data: { breadcrumb: 'DharmshalaList' } },
  { path: 'feedback-form/:id', component: FeedbackFormComponent, data: { breadcrumb: 'FeedbackForm' } },
  { path: 'pujan-booking', component: PujanBookingComponent, data: { breadcrumb: 'PujanBooking' } },
  { path: 'pujan-payment/:id', component: PujanBookingPaymentComponent, data: { breadcrumb: 'PujanBooking' } },
  // { path: 'garbh-graha-darshan', component: JalabhishekBookingComponent, data: { breadcrumb: 'GarbhGrahaDarshan' } },
  { path: 'ujjain-local-darshanarthi', component: UjjainLocalDarshanarthiComponent, data: { breadcrumb: 'UjjainLocalDarshanarthi' } },
  { path: 'bhasmarti-request', component: BhasmartiRequestComponent, data: { breadcrumb: 'Bhasm Aarti Advance Booking Request' } },
  { path: 'bhasmarti-request-success/:id', component: BhasmartiRequestSuccessComponent, data: { breadcrumb: 'Bhasm Aarti Advance Booking Request Success' } },
  // { path: 'nagchandreshwar-sheeghra-darshan', component: NagchandreshwarDarshanComponent, data: { breadcrumb: 'Nagchandreshwar Sheeghra Darshan' } },


  { path: 'sheeghra-darshan', loadChildren: () => import('./components/pages/paid-darshan/paid-darshan.module').then(m => m.PaidDarshanModule), data: { breadcrumb: 'SheegraDarshan' } },

  { path: 'home-v2', loadChildren: () => import('./components/pages/home-v2/home-v2.module').then(m => m.HomeV2Module), data: { breadcrumb: 'Homepage' } },
  { path: 'home-v3', loadChildren: () => import('./components/pages/home-v3/home-v3.module').then(m => m.HomeV3Module), data: { breadcrumb: 'Homepage' } },
  // About
  { path: 'history', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'History' } },
  { path: 'temple', loadChildren: () => import('./components/pages/temple/temple.module').then(m => m.TempleModule), data: { breadcrumb: 'Temple' } },
  { path: 'aarti-videos', loadChildren: () => import('./components/pages/aarti-videos/aarti-videos.module').then(m => m.AartiVideosModule), data: { breadcrumb: 'AartiVideos' } },
  { path: 'prohit-pujari', loadChildren: () => import('./components/pages/prohit-pujari/prohit-pujari.module').then(m => m.ProhitPujariModule), data: { breadcrumb: 'PurohitPujari' } },
  { path: 'temple-trustees', loadChildren: () => import('./components/pages/temple-trustees/temple-trustees.module').then(m => m.TempleTrusteesModule), data: { breadcrumb: 'TempleTrustees' } },
  { path: 'dharmshala-detail/:id', loadChildren: () => import('./components/pages/dharmshala-detail/dharmshala-detail.module').then(m => m.DharmshalaDetailModule), data: { breadcrumb: 'DharmshalaDetail' } },
  { path: 'puja-abhishek', loadChildren: () => import('./components/pages/puja-abhishek/puja-abhishek.module').then(m => m.PujaAbhishekModule), data: { breadcrumb: 'PujaAbhishek' } },
  { path: 'important-days', loadChildren: () => import('./components/pages/important-days/important-days.module').then(m => m.ImportantDaysModule), data: { breadcrumb: 'ImportantDays' } },
  { path: 'accommodation', loadChildren: () => import('./components/pages/accomodation/accomodation.module').then(m => m.AccomodationModule), data: { breadcrumb: 'Accommodation' } },
  { path: 'annakshetra', loadChildren: () => import('./components/pages/annakshetra/annakshetra.module').then(m => m.AnnakshetraModule), data: { breadcrumb: 'Annakshetra' } },
  { path: 'ujjain-history', loadChildren: () => import('./components/pages/ujjain-history/ujjain-history.module').then(m => m.UjjainHistoryModule), data: { breadcrumb: 'UjjainHistory' } },
  { path: 'places-to-visit', loadChildren: () => import('./components/pages/places-to-visit/places-to-visit.module').then(m => m.PlacesToVisitModule), data: { breadcrumb: 'PlacesToVisit' } },
  { path: 'how-to-reach', loadChildren: () => import('./components/pages/how-to-reach/how-to-reach.module').then(m => m.HowToReachModule), data: { breadcrumb: 'HowToReach' } },
  { path: 'privacy-policy', loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), data: { breadcrumb: 'PrivacyPolicy' } },
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'ContactUs' } },
  { path: 'news-event-list', loadChildren: () => import('./components/pages/news-event-list/news-event-list.module').then(m => m.NewsEventListModule), data: { breadcrumb: 'NewsEventList' } },
  {path: '**', redirectTo: ''}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
