import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../../../service/sort-list.service';
import { AppComponent } from 'src/app/app.component';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { CapturePhotoPopoverComponent } from '../../shared/capture-photo-popover/capture-photo-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-nagchandreshwar-darshan',
  templateUrl: './nagchandreshwar-darshan.component.html',
  styleUrls: ['./nagchandreshwar-darshan.component.css']
})
export class NagchandreshwarDarshanComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  darshanType = '';
  public env: any = environment;

  actualDarshanType = '';
  view: CalendarView = CalendarView.Month;
  viewCalendar = true;
  selectedDate = moment(new Date()).format('YYYY-MM-DD');
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  activeDayIsOpen: boolean = false;
  form: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  user_roles:any;
  darshan_type_id = 14;
  readDarshanType = '';
  viewCalanderStatus =  true;
  registrationDate = '';
  user_picture_file_name = '';
  id_proof_name = '';
  show_reference_limit_msg = '';
  checkLengthStatus = false;
  timeSlotList:any = [];
  calendarEndDate = moment(new Date()).format('YYYY-MM-DD');
  remainingCountData:any;
  restrictedDates:any = [];
  selectedDarshanObj:any;

  fileData:any = [
    { id: 0,
      user_picture_file_name: '',
      id_proof_name: '',
      imageloading: false,
      idProofloading: false
    }
  ];

  allRelations = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private appComponent: AppComponent,
    private modalService: NgbModal

  ) {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      date_of_visit: ['', [Validators.required]],
      darshan_type_id: ['' , [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      no_of_persons: ['', [Validators.required]],
      // time_of_visit: ['' ? [Validators.required] : []],
      slot_configuration_id: [''],
      id: [''],
      visitors_basic_details: this._formBuilder.array([this.getPersonRow()])
    });
  }



  ngOnInit() {
   
    this.getDarshanTypeId();
    this.getAllStates();
    this.getIdProofTypesList();

  }



  getControls() {
    return (this.form.get('visitors_basic_details') as FormArray).controls;
  }
  getControlsDetail(i:number) {
    return (this.form.get('visitors_basic_details') as FormArray).controls[i];
  }


 

  onClickCapturePhoto(index:any,key:any,controlName:string) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[index]['controls'];

    let document_id = child_cntrl[controlName];
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = null;
    capturePhotoPopoverComponent.headerName = key == 'user_picture_file_name' ? 'Capture Photo' : 'Capture ID Proof Photo';


    modalRef.result.then((result:any) => {
      if (result) {
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });
          const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
          let file_name = controlArray.controls[index].get('name').value;
          const imageFile = new File([blob], `${file_name}-image`, { type: 'image/png' });
          const obj = {
            id: index,
            user_picture_file_name: '',
            id_proof_name: '',
            imageloading: key != 'id_proof_name' ? true : false,
            idProofloading: key == 'id_proof_name' ? true : false
          }
          const isExits =  this.fileData.find((res:any)  =>  res.id == index);
          if(isExits) {
            // isExits.user_picture_file_name = '';
            isExits.imageloading = key != 'id_proof_name' ? true : false;
            isExits.idProofloading = key == 'id_proof_name' ? true : false;
          } else {
            this.fileData.push(obj);

          }
          this.postDocumentToServer(imageFile, document_id,key,index);
        });

      }
    });
  }



  getPersonRow() {
    return this._formBuilder.group({
      name: ['', [Validators.required]],
      gender: ['', Validators.required],
      relationship_id: [9, Validators.required],
      id_proof_type_id : ['', Validators.required],
      id_proof_number  : ['', [Validators.required,this.removeSpaces]],
      image_url: ['',[Validators.required]],
      id_proof_image_url: ['', [Validators.required]]
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }


  onChangeName() {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      controlArray.controls[0].get('name').setValue(`${this.form.value.first_name} ${this.form.value.last_name}`);
    }
  }
 
  onIdProofTypeChange(index:any) {
    this.checkLengthStatus = false;

    let aadharCardRgx = '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$';
    let drivingLicenseRgx = '^([A-Z]{2})(\\d{2}|\\d{3})[a-zA-Z]{0,1}-(\\d{4})-(\\d{7})$';
    let electionCommissionIDRgx = '^([a-zA-Z]){3}([0-9]){7}?$';
    let passportRgx = '^[A-Z][0-9]{8}$';


    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      controlArray.controls[index].get('id_proof_number').setValue('');
      if(controlArray.controls[index].get('id_proof_type_id').value == 1){
        this.checkLengthStatus = true;
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(aadharCardRgx)]);
      }
      if(controlArray.controls[index].get('id_proof_type_id').value == 2){
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(passportRgx)]);
      }
      if(controlArray.controls[index].get('id_proof_type_id').value == 3){
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(drivingLicenseRgx)]);
      }
      if(controlArray.controls[index].get('id_proof_type_id').value == 4){
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(electionCommissionIDRgx)]);
      }
      controlArray.controls[index].get('id_proof_number').updateValueAndValidity();

    }
  }


  getDarshanTypeId() {
    this.apiService.getPublicDataFromServer('/nagchandreshwar_darshan_type').subscribe(
      (res:any) => {
        this.darshan_type_id = res['data'];
        this.form.controls['darshan_type_id'].setValue(res.data.id);
        this.timeSlotList = [res.slot];
        this.form.controls['slot_configuration_id'].setValue(res.slot.id);
        this.form.controls['date_of_visit'].setValue(res.slot.from_date);
      },
      err => {
        console.log(err);
      }
    );
  }


  getAllStates() {
    this.apiService.getPublicDataFromServer('/states').subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofTypesList() {
    this.apiService.getPublicDataFromServer('/id_proof_types').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.id_proof_type_list = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  saveData() {
    const formObj = this.form.getRawValue();

    formObj.date_of_visit = moment(formObj.date_of_visit).format('YYYY-MM-DD');
    // if(formObj.time_of_visit){
    //   formObj.time_of_visit = moment(formObj.time_of_visit).format('HH:mm');
    // }

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/visitors', formObj).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.user_picture_file_name = '';
          this.id_proof_name = '';
          if(res.data && res.data.payment_status == 'Paid' && res.e_pass_url) {
            window.open(res.e_pass_url);
            // this.close();
            // this.createForm();
            this.viewCalendar = true;
            // window.location.reload();
          } else {
            this.router.navigate(['/payment', res['data']['id']]);
          }
          setTimeout(() => {
            this.appComponent.hideLoader();
          },200);
          this.fileData = [];
        },
        err => {
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          this.appComponent.hideLoader();
          console.log(err);
        }
      );
    }
  }


  onPersonCountChange(){

    let visitors_basic_details : any  = <FormArray>this.form.controls['visitors_basic_details'];
    if(this.form.value.no_of_persons >= 1) {
      if(visitors_basic_details.length < this.form.value.no_of_persons) {
        let persons = this.form.value.no_of_persons - visitors_basic_details.length;
        for (let i = 0; i < persons; i++) {
          visitors_basic_details.push(this._formBuilder.group({
            name: ['', [Validators.required]],
            gender: ['', Validators.required],
            relationship_id: ['',  ''],
            id_proof_type_id: ['',''],
            id_proof_number: ['', ''],
            image_url: ['',''],
            id_proof_image_url: ['','']
          }));
        }
      } else {
        let persons = visitors_basic_details.length;
        for (let i = persons - 1; i >  this.form.value.no_of_persons - 1; i--) {
          visitors_basic_details.removeAt(i);
        }
      }
    }
    
  }

  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  onChangePeopleProofNumber(i:any) {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('id_proof_number') && controlArray.controls[i].get('id_proof_number').value && !controlArray.controls[i].get('id_proof_number').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('id_proof_number').setValue('');
    }

  }

  createSpaceProofNumber(i:any){
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      if(controlArray.controls[i].get('id_proof_type_id').value == 1) {
        let id_proof_number =  controlArray.controls[i].get('id_proof_number').value;
        id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        controlArray.controls[i].get('id_proof_number').setValue(id_proof_number);
      }
    }
  }



  onChangePeopleName(i:any) {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('name') && controlArray.controls[i].get('name').value && !controlArray.controls[i].get('name').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('name').setValue('');
    }
  }

  public remove(i: number) {
    this.form.controls['no_of_persons'].setValue(this.form.value.no_of_persons - 1);
    const contrls = <FormArray>this.form.controls['visitors_basic_details'];
      contrls.removeAt(i);
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFileChange(event:any, i:any) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[i]['controls'];

    let document_id = child_cntrl['image_url'];

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const obj = {
        id: i,
        user_picture_file_name: '',
        id_proof_name: '',
        imageloading: true,
        idProofloading: false
      }
      const isExits =  this.fileData.find((res:any)  =>  res.id == i);
      if(isExits) {
        isExits.user_picture_file_name = '';
        isExits.imageloading = true;
      } else {
        this.fileData.push(obj);

      }
      reader.readAsDataURL(file);
      this.postDocumentToServer(event.target.files[0], document_id,'user_picture_file_name', i);

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  checkFileData(i:any,key:any) {
    const isExist = this.fileData.find((res:any) => res.id == i);
    return isExist && isExist[key] ? isExist[key] : '';
  }

  getIdProofFileDetails(event:any, i:any) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[i]['controls'];

    let document_id = child_cntrl['id_proof_image_url'];
    // let entity_tp_id = this.form.controls['entity_id'].value;

    let reader = new FileReader();

    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      const obj = {
        id: i,
        user_picture_file_name: '',
        id_proof_name: '',
        imageloading: false,
        idProofloading: true
      }
      const isExits =  this.fileData.find((res:any)  =>  res.id == i);
      if(isExits) {
        isExits.id_proof_name = '';
        isExits.idProofloading = true;
      } else {
        this.fileData.push(obj);

      }

      this.postDocumentToServer(event.target['files'][0], document_id,'id_proof_name',i);

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  postDocumentToServer(file_data:any, document_id:any,key:any,index:any) {
    const formData = new FormData();
    formData.append('file', file_data);


    this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
      let responsedata :  any = res;
        if(responsedata.data){
          document_id.setValue(responsedata.data);
        }
        this.fileData[index][key] = responsedata.data;
        this.fileData[index].imageloading =  false;
        this.fileData[index].idProofloading =  false;

    }, (error) => {
      this.fileData[index].imageloading =  false;
      this.fileData[index].idProofloading =  false;
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(error.error.error[0].message, 'error');
        console.log(error);
    });
  }

  // close() {
  //   const darshanType = this.activatedRoute.snapshot.params['darshanType'];
  //   this.router.navigate([`/registration/${darshanType}`]);
  // }

}

