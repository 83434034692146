import { Component, OnDestroy, OnInit, HostListener  } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-bhasmaarti-qr-code',
  templateUrl: './general-bhasmaarti-qr-code.component.html',
  styleUrls: ['./general-bhasmaarti-qr-code.component.css']
})
export class GeneralBhasmaartiQrCodeComponent implements OnInit, OnDestroy {

  qrCode = '';
  env = environment;
  apiLoadInterval: any = null;
  qrData: any = {};

  qrSize: number = 400; // Default size
  
  constructor(
        private apiService : ApiService
  ) { 
    this.adjustQrSize();
  }

  ngOnInit(): void {
    this.getGenralBhasmaartiQrCode();

    this.apiLoadInterval = setInterval(() => {
      this.getGenralBhasmaartiQrCode();
    },30000);
  }

  @HostListener('window:resize', [])
  onResize() {
    this.adjustQrSize();
  }

  adjustQrSize() {
    const screenWidth = window.innerWidth;
    this.qrSize = screenWidth * 0.4; // 40% of screen width
    if (this.qrSize > 600) this.qrSize = 600; // Max size limit
    if (this.qrSize < 150) this.qrSize = 150; // Min size limit
  }

  getGenralBhasmaartiQrCode() {
    this.apiService.getPublicDataFromServer('/general_bhasmaarti/current_qr_code?device_token=12345abcde').subscribe(
      (res:any) => {
        this.qrData = res.data;
        this.qrCode = `${this.env.projectWebUrl}/general-bhasmaarti-registration?qr_code=${res.data.qr_code}`;

      },
      err => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(){
    clearInterval(this.apiLoadInterval);
  }

}
