import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tender',
  templateUrl: './tender.component.html',
  styleUrls: ['./tender.component.css']
})
export class TenderComponent implements OnInit {
  environment = environment;

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";


  form!: FormGroup;
  formMode = 'Add';
  public suggestion_types = [];
  public tenderList = [];
  public years = ['2021','2022', '2023'];
  public selectedYear = '2023';


  constructor(
    private apiService : ApiService,
  ) {
  }

  ngOnInit() {
    this.getList();
  }



  getList() {
    this.apiService.getPublicDataFromServer(`/tenders?page=1&perpage=100000&year=${this.selectedYear}`).subscribe(
      (res:any) => {
        this.tenderList = res.data;
      },
      err => {
        console.log(err);
      }
    );
  }

}

