import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-money-donation-recived',
  templateUrl: './money-donation-recived.component.html',
  styleUrls: ['./money-donation-recived.component.css']
})
export class MoneyDonationRecivedComponent implements OnInit {
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";
  public donationList: any;
  public from_date = moment(new Date()).format('YYYY-MM-DD');
  public to_date = moment(new Date()).format('YYYY-MM-DD');
  public payment_status = 'Pending';
  public onChangeSearch = new Subject<string>();
  public perPage = 1000000;
  public totalCount = 0;
  public page = 1;
  public maxDate =  ''; 
  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.getList();
  }



  getList() {
    this.appComponent.showLoader();
    const from_date = moment(this.from_date).format('YYYY-MM-DD');
    const to_date = moment(this.to_date).format('YYYY-MM-DD');
    this.apiService.getDataFromServer(`/donations?date=${from_date}&end_date=${to_date}&page=${this.page}&perpage=${this.perPage}`).subscribe(
      (res: any) => {
        this.donationList = res.data;
        this.donationList.map((res:any) => {
          res.date = moment.utc(res.created_at).local().format('DD-MM-YYYY h:mm a z');
        });
        this.totalCount = res.total;
        this.appComponent.hideLoader();
      },
      err => {
        this.appComponent.hideLoader();
        console.log(err);
      }
    );
  }


}
