import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AppComponent } from 'src/app/app.component';
import { SortListService } from 'src/app/service/sort-list.service';
import { environment } from 'src/environments/environment';

declare var Razorpay: any;

@Component({
  selector: 'app-prasad-order',
  templateUrl: './prasad-order.component.html',
  styleUrls: ['./prasad-order.component.css']
})
export class PrasadOrderComponent implements OnInit {
  env = environment;
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  form!: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  user_roles:any;
  darshan_type_id = '';
  countriesData = [];
  user_picture_file_name = '';
  id_proof_name = '';
  checkLengthStatus = false;
  payment_type_id = 3;

  allPaymentGateways = [];
  payment_gateway_id:any = '';

  prasad = [
  ]

  myFilter = (d:any): boolean => {
    const time = d.get('hour');
    if(time >= 6 && time <= 20){
      this.timeDisable = false;
      return true;
    } else {
      this.timeDisable = true;
      return false;
    }
  }
  allRelations = [];

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private sortListService: SortListService,
    private _ngZone: NgZone
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getAllCountries();
    this.getIdProofTypesList();
    this.getAllPrasadList();
    this.getAllPaymentGateways();
  }


  createForm() {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      father_name: ['', [Validators.required]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      email: ['', [Validators.required, Validators.email]],
      id_proof_type_id : ['', Validators.required],
      id_proof_number  : ['', [Validators.required,this.removeSpaces]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
      address_line_1:  ['', [Validators.required]],
      shipping_cost: ['', [Validators.required]],
      total_amount:  ['', [Validators.required]],
      items_total_cost:  ['', [Validators.required]],
      payment_gateway_id: [this.payment_gateway_id,[Validators.required]],
      prasad_item_details: this._formBuilder.array([this.getPrasadItemRow()])
    });
  }

  getPrasadItemRow() {
    return this._formBuilder.group({
      prasad_id: ['', [Validators.required]],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      total_amount: ['', Validators.required],
    });
  }

  getControls() {
    return (this.form.get('prasad_item_details') as FormArray).controls;
  }
  getControlsDetail(i:number) {
    return (this.form.get('prasad_item_details') as FormArray).controls[i];
  }


  onChangeQuantity() {
    if(this.form.value.prasad){
      this.form.controls['price'].setValue(this.form.value.prasad.price);
    }

    if(this.form.value.quantity && this.form.value.prasad){
      this.form.controls['totalPrice'].setValue((Number(this.form.value.quantity) * Number(this.form.value.prasad.price)));
    }

  }


  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  public remove(i: number) {
    const contrls = <FormArray>this.form.controls['prasad_item_details'];
      contrls.removeAt(i);
  }

  addDetail() {
    let prasad_item_details : any  = <FormArray>this.form.controls['prasad_item_details'];
    prasad_item_details.push(this._formBuilder.group({
      prasad_id: ['', [Validators.required]],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      total_amount: ['', Validators.required],
    }));

  }


  createSpaceProofNumber(){
    console.log(this.form.value , 'form.value');
    if(this.form.controls['id_proof_type_id'].value == 1) {
      let id_proof_number = this.form.controls['id_proof_number'].value;
      id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
       this.form.controls['id_proof_number'].setValue(id_proof_number);
    }
  }

  onIdProofTypeChange() {
    this.checkLengthStatus = false;
    let aadharCardRgx = '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$';
    let drivingLicenseRgx = '^([A-Z]{2})(\\d{2}|\\d{3})[a-zA-Z]{0,1}-(\\d{4})-(\\d{7})$';
    let electionCommissionIDRgx = '^([a-zA-Z]){3}([0-9]){7}?$';
    let passportRgx = '^[A-Z][0-9]{8}$';
    this.form.controls['id_proof_number'].setValue('');

    if(this.form.controls['id_proof_type_id'].value == 1){
      this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(aadharCardRgx)]);
      this.checkLengthStatus = true;
      }
      if(this.form.controls['id_proof_type_id'].value == 2){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(passportRgx)]);
      }
      if(this.form.controls['id_proof_type_id'].value == 3){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(drivingLicenseRgx)]);
      }
      if(this.form.controls['id_proof_type_id'].value == 4){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(electionCommissionIDRgx)]);
      }
      this.form.controls['id_proof_number'].updateValueAndValidity();
  }


  getAllStates(id:any) {
    this.apiService.getPublicDataFromServer(`/states?country_id=${id}`).subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllPaymentGateways() {
    this.apiService.getPublicDataFromServer('/payment_gateways').subscribe(
      (res:any) => {
        this.allPaymentGateways = res['data'].filter((val : any) => val.is_enable);
      },
      err => {
        console.log(err);
      }
    );
  }



  getAllCountries() {
    this.apiService.getPublicDataFromServer('/countries').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.countriesData = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }


  getAllPrasadList() {
    this.apiService.getPublicDataFromServer('/prasads').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.prasad = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }


  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofTypesList() {
    this.apiService.getPublicDataFromServer('/id_proof_types').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.id_proof_type_list = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  cost_calculations(index:any) {

    const controlArray:any = <FormArray> this.form.get('prasad_item_details');
    if(controlArray.controls && controlArray.controls.length) {
      if(controlArray.controls[index].get('prasad_id').value){
        const isExist:any =  this.prasad.find((res:any) => (controlArray.controls[index].get('prasad_id').value == res.id));
        if(isExist && controlArray.controls[index].get('quantity').value) {
          const quantity = Number(controlArray.controls[index].get('quantity').value)
          controlArray.controls[index].get('price').setValue(isExist.unit_price);
          controlArray.controls[index].get('total_amount').setValue(isExist.unit_price * quantity);
        }
      }
    }

    const isNotDone = this.form.value.prasad_item_details.find((res:any) => !res.prasad_id || !res.quantity);
    if(!isNotDone){
      const data = {
        prasad_item_details: this.form.value.prasad_item_details
      }
      this.apiService.postPublicDataToServer('/cost_calculations', data).subscribe(
        (res:any) => {
         this.form.controls['shipping_cost'].setValue(res.shipping_cost ?  res.shipping_cost : '');
         this.form.controls['total_amount'].setValue(res.total_amount ?  res.total_amount : '');
         this.form.controls['items_total_cost'].setValue(res.items_total_cost ?  res.items_total_cost : '');
        },
        err => {
          console.log(err);
        }
      );
    }
  }







  saveData() {
    const formObj = this.form.getRawValue();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/prasad_orders', formObj).subscribe(
        (res:any) => {
          this.id_proof_name = '';
          this.user_picture_file_name = '';
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.saveDataOfPayment(res.data);
          this.createForm();
          setTimeout(() => {
            this.appComponent.hideLoader();
          },200);
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  saveDataOfPayment(data:any) {
    const paymentData = {
      payer_first_name: this.form.value.first_name,
      payer_last_name: this.form.value.last_name,
      service_id: data.id,
      payment_type_id: this.payment_type_id,
      payer_contact: this.form.value.mobile_no,
      payer_email: this.form.value.email,
      payer_address: this.form.value.address,
      service_type: 'Prasad Order',
      is_from_mobile: false,
      darshan_type: '',
      payment_gateway_id: this.form.value.payment_gateway_id
    }
    this.appComponent.showLoader();
    this.apiService.postPublicDataToServer('/make_payment', paymentData).subscribe(
      (res:any) => {
        if(res.success) {
          if(this.form.value.payment_gateway_id == 2){
            let _data = res.data;
            let options = {
              "key": this.env.rzp_key,
              "amount": (_data.amount * 100),
              "currency": _data.currency,
              "name": "Mahakaleshwar Temple Prabhand Samiti Ujjain",
              "order_id": _data.id,
              "handler": (response: any) => {
                this.verifyPayment(response);
              },
              "prefill": {
                "name": _data.prefill_fields.name,
                "email": _data.prefill_fields.email,
                "contact": _data.prefill_fields.contact
              }
            };
            var rzp1 = new Razorpay(options);

            rzp1.open();
          } else {
            window.location.replace(res.url);
          }        }
        this.appComponent.showMessageAlert(res['message'], 'success');
        this.createForm();
        setTimeout(() => {
          this.appComponent.hideLoader();
        },200);
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }


  onChangeDate() {
    const date = moment(this.form.value.date_of_visit).format('YYYY/MM/DD');
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    if(this.form.value.darshan_type_id == 1 && date == currentDate && this.user_roles != 'general_bhasmaarti') {
      this.dateErrorMsg = "You can't select today date for bhasma aarti"
    } else {
      this.dateErrorMsg = '';
    }
  }


  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  onCountryChange(_value:any) {
    this.form.controls['state_id'].setValue('');
    this.form.controls['city_id'].setValue('');

    this.getAllStates(_value.target.value);
  }



  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  verifyPayment(_data : any) {
    this.appComponent.showLoader();
    let formObj = {
      razorpay_payment_id: _data.razorpay_payment_id,
      razorpay_order_id: _data.razorpay_order_id,
      razorpay_signature: _data.razorpay_signature
    };

    this.apiService.postPublicDataToServer('/verify_payment', formObj).subscribe(
      (res : any) => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(res['message'], 'success');
        this._ngZone.run(()=>{
          this.router.navigate(['/payment-status'], {queryParams: {redirect: true , clientTxnId: res['data']['clientTxnId'] }});
        });

      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }
  // onFileChange(event, i) {

  //  this.form.value.image_url

  //   let document_id = this.form.value.image_url;
  //   let reader = new FileReader();

  //   if (event.target.files && event.target.files.length) {
  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);
  //     this.user_picture_file_name = file.name;

  //     this.postDocumentToServer(event.target.files[0], document_id , 'image_url');

  //     reader.onload = () => {
  //       this.cd.markForCheck();
  //     };
  //   }
  // }


  // getIdProofFileDetails(e, i) {
  //   this.form.value.id_proof_image_url
  //   let document_id = this.form.value.id_proof_image_url;

  //   let reader = new FileReader();

  //   if (event.target['files'] && event.target['files'].length) {
  //     const [file] = event.target['files'];
  //     reader.readAsDataURL(file);
  //     this.id_proof_name = file.name;

  //     this.postDocumentToServer(event.target['files'][0], document_id, 'id_proof_image_url');

  //     reader.onload = () => {
  //       this.cd.markForCheck();
  //     };
  //   }
  // }


  // postDocumentToServer(file_data, document_id,keyName?) {
  //   const formData = new FormData();
  //   formData.append('file', file_data);

  //   this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
  //     let responsedata :  any = res;
  //     this.form.controls[keyName].setValue(responsedata.documentURL);

  //   }, (error) => {

  //   });
  // }

  close() {
    this.router.navigate(['/home']);

  }

}
