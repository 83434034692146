import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";
  public paymentDetail:any = {
    spRespStatus: 'PENDING'
  };
  private apiLoadInterval:any = null;
  public e_pass = '';
  public reciept = '';
  public id_card = '';

  

  constructor(
    private apiService: ApiService,
    private appComponent: AppComponent,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.appComponent.showLoader();
    let url = this.route.routeConfig?.path;
    url = url?.replace(/-/gi, '_');

    this.route.queryParams
    .subscribe(params => {
      if(params.redirect && params.clientTxnId){
        this.getPaymentDetail(`/${url}?redirect=${params.redirect}&clientTxnId=${params.clientTxnId}`);
      }
    }
  );

  }

  ngOnDestroy() {
    clearTimeout(this.apiLoadInterval);
  }

  viewPass() {
    window.open(this.e_pass);

  }

  viewReciept(url:any){
    window.open(url);
  }


  getPaymentDetail(url:any) {
    this.apiService.getPublicDataFromServer(url).subscribe(
      (res:any) => {
        this.appComponent.hideLoader();
        this.paymentDetail = res['data'];
        this.e_pass = res.e_pass_url ? res.e_pass_url : '';
        clearTimeout(this.apiLoadInterval);
        if(this.paymentDetail.spRespStatus == 'PENDING') {
          this.apiLoadInterval = setTimeout(() => {
            this.getPaymentDetail(url);
          },15000);
        } else if(this.paymentDetail.spRespStatus == 'SUCCESS' && this.e_pass){
          setTimeout(() => {
            window.open(this.e_pass);
          },2000);
        }
        if(this.paymentDetail.spRespStatus == 'SUCCESS' && res.reciept){
          if(res.reciept.receipt_url){
            setTimeout(() => {
              window.open(res.reciept.receipt_url);
              this.reciept = res.reciept.receipt_url;
            },1500);
          }
          // if(res.reciept.id_card_url){
          //   setTimeout(() => {
          //     window.open(res.reciept.id_card_url);
          //     this.id_card = res.reciept.id_card_url;
          //   },1500);
          // }
         
        }
      },
      err => {
        this.paymentDetail = {
          spRespStatus: 'FAILED'
        };
        console.log(err);
      }
    );
  }

}
