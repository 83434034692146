import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/service/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-bhasmaarti-status',
  templateUrl: './general-bhasmaarti-status.component.html',
  styleUrls: ['./general-bhasmaarti-status.component.css']
})
export class GeneralBhasmaartiStatusComponent implements OnInit {

  env = environment;
    classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
    ftlogo = "assets/images/logo-2.png";
    ftbg = "assets/images/footer-1.jpg";
   
    visitor_id;
    visitorDetails: any = {};
    
   
  
    constructor(
      private apiService: ApiService,
      private appComponent: AppComponent,
      private route: ActivatedRoute,
      private router: Router,
      ) {
      this.visitor_id = this.route.snapshot.params.id;
  
    }
  
    ngOnInit() {
      this.getVisitor();
    }
  
  
    getVisitor() {
      this.appComponent.showLoader();
      this.apiService.getPublicDataFromServer('/visitors/' + this.visitor_id).subscribe(
        res => {
          this.visitorDetails = res;
          this.visitorDetails.data.date_of_visit = moment.utc(this.visitorDetails.data.date_of_visit).local().format('DD-MM-YYYY');
          this.appComponent.hideLoader();
         
        },
        err => {
          this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  
  
  
    
  
    viewPass() {
      if(this.visitorDetails && this.visitorDetails.data && this.visitorDetails.data.e_pass_url) {
          window.open(this.visitorDetails.data.e_pass_url);
      }
      // this.appComponent.showLoader();
      // this.apiService.getDataFromServer('/generate_entry_pass/' + this.visitor_id).subscribe(
      //   (res:any) => {
      //     this.appComponent.hideLoader();
      //     window.open(res['data'][0]);
      //     this.appComponent.showMessageAlert(res['message'], 'success');
      //     this.back();
      //   },
      //   err => {
      //     this.appComponent.hideLoader();
      //     console.log(err);
      //   }
      // );
    }
  
   
    
  
    
  
  }
  
