import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.css']
})
export class ImagePopupComponent implements OnInit {

  public env: any = environment;
  
  allDharmshalas = {};
  error: any;
  isCaptured: boolean = false;
  data:any;
  holdStreamData:any;


  constructor(
    public activeModal: NgbActiveModal,

  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  public closeModal() {
    this.activeModal.close('');
  }

}
