import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { AppComponent } from 'src/app/app.component';
import { SortListService } from 'src/app/service/sort-list.service';
import { CapturePhotoPopoverComponent } from '../../shared/capture-photo-popover/capture-photo-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

declare var Razorpay: any;


@Component({
  selector: 'app-donate-money-online',
  templateUrl: './donate-money-online.component.html',
  styleUrls: ['./donate-money-online.component.css']
})
export class DonateMoneyOnlineComponent implements OnInit {
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";


  form!: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  user_roles:any;
  darshan_type_id = '';
  donatePurposeData = [];
  countriesData = [];
  user_picture_file_name = '';
  id_proof_name = '';
  payment_type_id = 3;
  checkLengthStatus = false;
  imageUrlLoading = false;
  idProofLoading = false;
  public env: any = environment;

  allRelations = [];
  allPaymentGateways = [];
  payment_gateway_id:any = '';

  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private appComponent: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private _ngZone: NgZone

  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getAllCountries();
    this.getIdProofTypesList();
    this.getAllDonatePurpose();
    this.getAllPaymentGateways();
  }


  createForm() {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      father_name: ['', [Validators.required]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      id_proof_type_id : ['', Validators.required],
      id_proof_number  : ['', [Validators.required,this.removeSpaces]],
      pan_card_no  : ['',[Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')]],
      image_url: ['', [Validators.required]],
      id_proof_image_url: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
      address_line_1:  ['', [Validators.required]],
      donation_purpose_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      payment_gateway_id: [this.payment_gateway_id,[Validators.required]]
    });
  }


  onChangePeopleProofNumber() {
    // const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (this.form.controls['id_proof_number'] && this.form.controls['id_proof_number'].value && !this.form.controls['id_proof_number'].value.replace(/\s/g, '').length) {
      this.form.controls['id_proof_number'].setValue('');
    }
  }



  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }


  onIdProofTypeChange() {
    this.checkLengthStatus = false;

    let aadharCardRgx = '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$';
    let drivingLicenseRgx = '^([A-Z]{2})(\\d{2}|\\d{3})[a-zA-Z]{0,1}-(\\d{4})-(\\d{7})$';
    let electionCommissionIDRgx = '^([a-zA-Z]){3}([0-9]){7}?$';
    let passportRgx = '^[A-Z][0-9]{8}$';
    this.form.controls['id_proof_number'].setValue('');

    if(this.form.controls['id_proof_type_id'].value == 1){
      this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(aadharCardRgx)]);
      this.checkLengthStatus = true;
      }
      if(this.form.controls['id_proof_type_id'].value == 2){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(passportRgx)]);
      }
      if(this.form.controls['id_proof_type_id'].value == 3){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(drivingLicenseRgx)]);
      }
      if(this.form.controls['id_proof_type_id'].value == 4){
        this.form.controls['id_proof_number'].setValidators([Validators.required, Validators.pattern(electionCommissionIDRgx)]);
      }
      this.form.controls['id_proof_number'].updateValueAndValidity();
  }

    createSpaceProofNumber(){
      if(this.form.controls['id_proof_type_id'].value == 1) {
        let id_proof_number = this.form.controls['id_proof_number'].value;
        id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
         this.form.controls['id_proof_number'].setValue(id_proof_number);
      }
  }



  getAllStates(id:any) {
    this.apiService.getPublicDataFromServer(`/states?country_id=${id}`).subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllDonatePurpose() {
    this.apiService.getPublicDataFromServer('/donation_purposes').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'title').then(res => {
          this.donatePurposeData = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllPaymentGateways() {
    this.apiService.getPublicDataFromServer('/payment_gateways').subscribe(
      (res:any) => {
        this.allPaymentGateways = res['data'].filter((val : any) => val.is_enable);
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCountries() {
    this.apiService.getPublicDataFromServer('/countries').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.countriesData = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }


  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofTypesList() {
    this.apiService.getPublicDataFromServer('/id_proof_types').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.id_proof_type_list = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }


  saveData() {
    const formObj = this.form.getRawValue();

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/donations', formObj).subscribe(
        (res:any) => {
          // this.router.navigate(['/payment', res['data']['id']]);
          this.id_proof_name = '';
          this.user_picture_file_name = '';
          this.saveDataOfPayment(res.data)

        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  saveDataOfPayment(data:any) {
    const paymentData = {
      payer_first_name: this.form.value.first_name,
      payer_last_name: this.form.value.last_name,
      service_id: data.id,
      payment_type_id: this.payment_type_id,
      payer_contact: this.form.value.mobile_no,
      payer_email: this.form.value.email,
      payer_address: this.form.value.address,
      service_type: 'Donation',
      is_from_mobile: false,
      darshan_type: '',
      payment_gateway_id: this.form.value.payment_gateway_id
    }
    this.appComponent.showLoader();
    this.apiService.postPublicDataToServer('/make_payment', paymentData).subscribe(
      (res:any) => {
        if(res.success) {
          if(this.form.value.payment_gateway_id == 2){
            let _data = res.data;
            let options = {
              "key": this.env.rzp_key,
              "amount": (_data.amount * 100),
              "currency": _data.currency,
              "name": "Mahakaleshwar Temple Prabhand Samiti Ujjain",
              "order_id": _data.id,
              "handler": (response: any) => {
                this.verifyPayment(response);
              },
              "prefill": {
                "name": _data.prefill_fields.name,
                "email": _data.prefill_fields.email,
                "contact": _data.prefill_fields.contact
              }
            };
            var rzp1 = new Razorpay(options);

            rzp1.open();
          } else {
            window.location.replace(res.url);
          }
        }
        this.appComponent.showMessageAlert(res['message'], 'success');
        this.createForm();
        setTimeout(() => {
          this.appComponent.hideLoader();
        },200);
      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }


  onChangeDate() {
    const date = moment(this.form.value.date_of_visit).format('YYYY/MM/DD');
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    if(this.form.value.darshan_type_id == 1 && date == currentDate && this.user_roles != 'general_bhasmaarti') {
      this.dateErrorMsg = "You can't select today date for bhasma aarti"
    } else {
      this.dateErrorMsg = '';
    }
  }


  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  onCountryChange(_value:any) {
    this.form.controls['state_id'].setValue('');
    this.form.controls['city_id'].setValue('');

    this.getAllStates(_value.target.value);
  }



  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFileChange(event:any) {
    this.imageUrlLoading = true;

    let document_id = this.form.value.image_url;
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      // this.user_picture_file_name = file.name;

      this.postDocumentToServer(event.target.files[0], document_id , 'image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }



  getIdProofFileDetails(event:any) {
    this.idProofLoading = true;
    this.form.value.id_proof_image_url
    let document_id = this.form.value.id_proof_image_url;

    let reader = new FileReader();

    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      // this.id_proof_name = file.name;

      this.postDocumentToServer(event.target['files'][0], document_id, 'id_proof_image_url');

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  onClickCapturePhoto(keyName:any) {
    let document_id = this.form.value.image_url;
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = null;
    capturePhotoPopoverComponent.headerName = keyName == 'image_url' ? 'Capture Photo' : 'Capture ID Proof Photo';


    modalRef.result.then((result:any) => {
      if (result) {
        if(keyName == 'id_proof_image_url') {
          this.idProofLoading = true;
        } else {
          this.imageUrlLoading = true;
        }
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });

          const imageFile = new File([blob], `${this.form.value.first_name}-image`, { type: 'image/png' });

          this.postDocumentToServer(imageFile, document_id, keyName);
        });

      }
    });
  }


  postDocumentToServer(file_data:any, document_id:any,keyName?:any) {
    const formData = new FormData();
    formData.append('file', file_data);

    this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
      let responsedata :  any = res;
      if(responsedata && responsedata.status_code == 200){
        this.form.controls[keyName].setValue(responsedata.data);
        this.user_picture_file_name = keyName == 'image_url' ? responsedata.data : this.user_picture_file_name;
        this.imageUrlLoading = false;
        this.id_proof_name = keyName != 'image_url' ? responsedata.data : this.id_proof_name;
        this.idProofLoading = false;
      }
    }, (error) => {

    });
  }

  verifyPayment(_data : any) {
    this.appComponent.showLoader();
    let formObj = {
      razorpay_payment_id: _data.razorpay_payment_id,
      razorpay_order_id: _data.razorpay_order_id,
      razorpay_signature: _data.razorpay_signature
    };

    this.apiService.postPublicDataToServer('/verify_payment', formObj).subscribe(
      (res : any) => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(res['message'], 'success');
        this._ngZone.run(()=>{
          this.router.navigate(['/payment-status'], {queryParams: {redirect: true , clientTxnId: res['data']['clientTxnId'] }});
        });

      },
      err => {
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
        console.log(err);
      }
    );
  }

  close() {
    this.router.navigate(['/home']);

  }

}
