import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from 'angular-crumbs';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { QrCodeModule } from 'ng-qrcode';


export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY',
  parseInput: 'DD-MM-YYYY',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';


import {OWL_DATE_TIME_FORMATS, OwlDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { PaymentDetailComponent } from './components/pages/payment-detail/payment-detail.component';
import { SharedModule } from './components/shared/shared.module';
import { PaymentStatusComponent } from './components/pages/payment-status/payment-status.component';
import { DonateMoneyOnlineComponent } from './components/pages/donate-money-online/donate-money-online.component';
import { MoneyDonationRecivedComponent } from './components/pages/money-donation-recived/money-donation-recived.component';
import { SuggestionComponent } from './components/pages/suggestion/suggestion.component';
import { TenderComponent } from './components/pages/tender/tender.component';
import { ManagementCommitteComponent } from './components/pages/management-committe/management-committe.component';
import { PrasadOrderComponent } from './components/pages/prasad-order/prasad-order.component';
import { DownloadEPassComponent } from './components/pages/download-e-pass/download-e-pass.component';
import { DharmshalaBookingComponent } from './components/pages/dharmshala-booking/dharmshala-booking.component';
import { DharmshalaBookingPaymentComponent } from './components/pages/dharmshala-booking-payment/dharmshala-booking-payment.component';
import { DharmshalaListComponent } from './components/pages/dharmshala-list/dharmshala-list.component';
import { FeedbackFormComponent } from './components/pages/feedback-form/feedback-form.component';
import { PujanBookingComponent } from './components/pages/pujan-booking/pujan-booking.component';
import { PujanBookingPaymentComponent } from './components/pages/pujan-booking-payment/pujan-booking-payment.component';
import { JalabhishekBookingComponent } from './components/pages/jalabhishek-booking/jalabhishek-booking.component';
import { UjjainLocalDarshanarthiComponent } from './components/pages/ujjain-local-darshanarthi/ujjain-local-darshanarthi.component';
import { ImagePopupComponent } from './components/pages/image-popup/image-popup.component';
import { BhasmartiRequestComponent } from './components/pages/bhasmarti-request/bhasmarti-request.component';
import { BhasmartiRequestSuccessComponent } from './components/pages/bhasmarti-request-success/bhasmarti-request-success.component';
import { NagchandreshwarDarshanComponent } from './components/pages/nagchandreshwar-darshan/nagchandreshwar-darshan.component';
import { GeneralBhasmaartiQrCodeComponent } from './components/pages/general-bhasmaarti-qr-code/general-bhasmaarti-qr-code.component';
import { GeneralBhasmaartiRegistrationComponent } from './components/pages/general-bhasmaarti-registration/general-bhasmaarti-registration.component';
import { GeneralBhasmaartiStatusComponent } from './components/pages/general-bhasmaarti-status/general-bhasmaarti-status.component';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    PaymentDetailComponent,
    PaymentStatusComponent,
    DonateMoneyOnlineComponent,
    MoneyDonationRecivedComponent,
    SuggestionComponent,
    TenderComponent,
    ManagementCommitteComponent,
    PrasadOrderComponent,
    DownloadEPassComponent,
    DharmshalaBookingComponent,
    DharmshalaBookingPaymentComponent,
    DharmshalaListComponent,
    FeedbackFormComponent,
    PujanBookingComponent,
    PujanBookingPaymentComponent,
    JalabhishekBookingComponent,
    UjjainLocalDarshanarthiComponent,
    ImagePopupComponent,
    BhasmartiRequestComponent,
    BhasmartiRequestSuccessComponent,
    NagchandreshwarDarshanComponent,
    GeneralBhasmaartiQrCodeComponent,
    GeneralBhasmaartiRegistrationComponent,
    GeneralBhasmaartiStatusComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    BreadcrumbModule,
    NgbModule,
    SlickCarouselModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    QrCodeModule
  ],
  entryComponents: [ImagePopupComponent],
  providers : [
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Lcz_m8pAAAAAH6w3fczAqg7mqYU_cCs9G3TVblA',
      } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
