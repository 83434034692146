import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from '../../../app.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SortListService} from '../../../service/sort-list.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  form!: FormGroup;
  allQuestions = [];
  visitor_id = '';


  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private appComponent: AppComponent,
    private modalService: NgbModal

  ) {
     this.visitor_id = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
   this.CreateForm();
   this.getAllQuestions();
  }


  CreateForm() {
    this.form = this._formBuilder.group({
    });
  }

  getAllQuestions() {
    this.apiService.getPublicDataFromServer('/questions').subscribe(
      (res:any) => {
        this.allQuestions = res.data;
        this.allQuestions.forEach((val)=>{
          this.form.addControl(val['id'], this._formBuilder.control('', [Validators.required]));
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  saveData() {
    const formObj = this.form.getRawValue();

      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/visitor_feedback/' + encodeURIComponent(this.visitor_id), formObj).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.CreateForm();
          this.router.navigate(['/']);
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
}
