import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-management-committe',
  templateUrl: './management-committe.component.html',
  styleUrls: ['./management-committe.component.css']
})
export class ManagementCommitteComponent implements OnInit {
  public dataList:any = [];

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";
  constructor(
    private apiService : ApiService,
    private appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
    this.getList();
  }
  
  getList() {
    this.appComponent.showLoader();
    this.apiService.getPublicDataFromServer('/temple_trustee').subscribe(
      (res:any) => {
        this.appComponent.hideLoader();
        this.dataList = res.data;
      },
      err => {
        this.appComponent.hideLoader();
        console.log(err);
      }
    );
  }

}
