import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-download-e-pass',
  templateUrl: './download-e-pass.component.html',
  styleUrls: ['./download-e-pass.component.css']
})
export class DownloadEPassComponent implements OnInit {
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";
  registrationNo = '';
  transaction_validation_msg = '';
  form!: FormGroup;
  otp = '';
  otpError = '';
  successMsg = '';
  e_pass = '';

  constructor(
    private _formBuilder: FormBuilder,
    private appComponent: AppComponent,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  onChangeRegNo(){
    this.successMsg = '';
    this.otpError = '';
    this.transaction_validation_msg = '';
  }

  createForm() {
    this.form = this._formBuilder.group({
      reg_no: ['', [Validators.required]],
    });
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

 save(){
  this.appComponent.showLoader();
  this.apiService.postPublicDataToServer('/generate_e_pass_otp', this.form.value).subscribe(
    (res:any) => {
      if(res.success) {
        this.successMsg = 'sent a verification code on your registered mobile number'      
      }
      this.appComponent.showMessageAlert(res['message'], 'success');
      this.appComponent.hideLoader();
    },
    (err:any) => {
      this.transaction_validation_msg = err.error.error[0].message;
      this.appComponent.hideLoader();
      this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      console.log(err);
    }
  );
 }
 

 verifyOTP(){
   const data = {
    reg_no: this.form.value.reg_no,
    otp: this.otp
   }
  this.appComponent.showLoader();
  this.apiService.postPublicDataToServer('/verify_e_pass_otp',data).subscribe(
    (res:any) => {
      if(res.success){
        const e_pass_url = res.data && res.data.e_pass_url ?  res.data.e_pass_url : '';
        if(e_pass_url){
          window.open(e_pass_url);
          this.e_pass = e_pass_url;
        }
        
        this.appComponent.showMessageAlert(res['message'], 'success');
        this.appComponent.hideLoader();
        this.clear();
      }

    },
    (err:any) => {
      this.otpError = err.error.error[0].message;
      this.appComponent.hideLoader();
      this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
      console.log(err);
    }
  );
 }

 clear() {
   this.form.reset();
   this.successMsg = '';
   this.otpError = '';
   this.transaction_validation_msg = '';
   this.otp = '';
 }

//  viewPass() {
//   window.open(this.e_pass);

// }

}
