import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgMasonryGridModule } from 'ng-masonry-grid';


import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { BlogSidebarComponent } from './blog-sidebar/blog-sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CounterComponent } from './counter/counter.component';
import { FilterEventComponent } from './filter-event/filter-event.component';
import { FilterEventTwoComponent } from './filter-event-two/filter-event-two.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderTwoComponent } from './header-two/header-two.component';
import { HeaderThreeComponent } from './header-three/header-three.component';
import { MasonryBlogComponent } from './masonry-blog/masonry-blog.component';
import { SpeakerComponent } from './speaker/speaker.component';
import { SponsersComponent } from './sponsers/sponsers.component';
import { ItemComponent } from './item/item.component';
import { ShopSidebarComponent } from './shop-sidebar/shop-sidebar.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {TranslateModule} from '@ngx-translate/core';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { CapturePhotoPopoverComponent } from './capture-photo-popover/capture-photo-popover.component';
import { AlertMessagePopoverComponent } from './alert-message-popover/alert-message-popover.component';
import { RecaptchaModule, RecaptchaFormsModule , RecaptchaSettings, RECAPTCHA_SETTINGS} from 'ng-recaptcha';

@NgModule({
  declarations: [BackToTopComponent, BlogSidebarComponent, BreadcrumbComponent, CounterComponent, FilterEventComponent, FilterEventTwoComponent, FooterComponent, HeaderComponent, HeaderTwoComponent, HeaderThreeComponent, MasonryBlogComponent, SpeakerComponent, SponsersComponent, ItemComponent, ShopSidebarComponent, RegistrationFormComponent, CapturePhotoPopoverComponent, AlertMessagePopoverComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,
    NgMasonryGridModule,
    NgCircleProgressModule.forRoot(),
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    TranslateModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [BackToTopComponent, BlogSidebarComponent, BreadcrumbComponent, CounterComponent, FilterEventComponent, FilterEventTwoComponent, FooterComponent, HeaderComponent, HeaderTwoComponent, HeaderThreeComponent, MasonryBlogComponent, SpeakerComponent, SponsersComponent, ShopSidebarComponent, RegistrationFormComponent, TranslateModule,
    CommonModule,
    RouterModule,
    NgbModule,
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,
    NgMasonryGridModule,
    NgCircleProgressModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    CalendarModule,
    RecaptchaModule,
    RecaptchaFormsModule],
  entryComponents: [CapturePhotoPopoverComponent,AlertMessagePopoverComponent],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: "6Lcz_m8pAAAAAH6w3fczAqg7mqYU_cCs9G3TVblA" } as RecaptchaSettings,
  },]

})
export class SharedModule { }
