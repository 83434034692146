import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../../../service/sort-list.service';
import { AppComponent } from 'src/app/app.component';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dharmshala-list',
  templateUrl: './dharmshala-list.component.html',
  styleUrls: ['./dharmshala-list.component.css']
})
export class DharmshalaListComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  @Input() darshanType = '';
  public env: any = environment;

  allDharmshalas = [];

  constructor(
    private apiService : ApiService,
    private sortListService: SortListService,

  ) {
   
  }



  ngOnInit() {
    const date = new Date();
      date.setDate( date.getDate() + 1);
    this.getAllDharmshalas();

  }

  getAllDharmshalas() {
    this.apiService.getPublicDataFromServer('/dharmashala_list').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allDharmshalas = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

}