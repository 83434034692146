import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  base_url = environment.apiBaseUrl;
  bhasmaarti_date = new Date();
  next_month_bhasmaarti = new Date();

  constructor(private http: HttpClient) {
    let date = new Date();
    let next_month_bhasmaarti_date = new Date();
    if(date.getDate() > 20 ||  (date.getDate() == 20 && date.getHours() >= 8)){
      date.setMonth(date.getMonth()+1, 1);
        this.bhasmaarti_date = date;
        next_month_bhasmaarti_date.setMonth(next_month_bhasmaarti_date.getMonth()+1);
         this.next_month_bhasmaarti = next_month_bhasmaarti_date;

      } else {
    date.setDate( date.getDate() + 1);
    this.bhasmaarti_date = date;
    next_month_bhasmaarti_date.setMonth(next_month_bhasmaarti_date.getMonth()+1);
    this.next_month_bhasmaarti = next_month_bhasmaarti_date;

      }
  }

  postDataToServer(url:any, body:any) {

    return this.http.post(this.base_url + url, body);
  }

  public getBlobFileFromServer(relativeUrl: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','*');
    return this.http.get(relativeUrl, { headers : headers,  responseType: 'blob' });
  }
  

  getDataFromServer(url:any) {

    return this.http.get(this.base_url + url);
  }

  getUserDetail(url:any) {
    return this.http.get(this.base_url + url, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});

  }

  putDataToServer(url:any, body:any) {

    return this.http.put(this.base_url + url, body);
  }

  deleteDataFromServer(url:any) {

    return this.http.delete(this.base_url + url);
  }

  postPublicDataToServer(url:any, body:any) {

    return this.http.post(this.base_url + url, body, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});
  }


  getPublicDataFromServer(url:any) {

    return this.http.get(this.base_url + url, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});
  }

  putPublicDataToServer(url:any, body:any) {

    return this.http.put(this.base_url + url, body, {headers : new HttpHeaders().set('Authorization' , 'No-auth')});
  }

  
}
