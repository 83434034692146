import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { SortListService } from '../../../service/sort-list.service';
import { AppComponent } from 'src/app/app.component';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  TemplateRef,
  OnDestroy,
  Injectable,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
  CalendarUtils
} from 'angular-calendar';
import { subWeeks, startOfMonth, endOfMonth, addWeeks, startOfWeek } from 'date-fns';
import { GetMonthViewArgs, MonthView } from 'calendar-utils';
import { Subject } from 'rxjs';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE} from '@danielmoncada/angular-datetime-picker';
import {OwlMomentDateTimeModule} from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { CapturePhotoPopoverComponent } from '../capture-photo-popover/capture-photo-popover.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY',
  parseInput: 'DD-MM-YYYY',
  datePickerInput: 'DD-MM-YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@Injectable()
export class MyCalendarUtils extends CalendarUtils {
  // getMonthView(args: GetMonthViewArgs): MonthView {
  //   args.viewEnd = addWeeks(endOfMonth(args.viewDate), 4);
  //   return super.getMonthView(args);
  getMonthView(args: GetMonthViewArgs): MonthView {
    args.viewStart = startOfWeek(args.viewDate, {weekStartsOn : 1});
    args.viewEnd = addWeeks(args.viewDate, 4);
    return super.getMonthView(args);
  }
}


@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css'],
  providers: [{ provide: OwlMomentDateTimeModule, useClass: OwlMomentDateTimeModule, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    {
      provide: CalendarUtils,
      useClass: MyCalendarUtils,
    }]


})
export class RegistrationFormComponent implements OnInit, OnDestroy {

  @Input() darshanType = '';
  public env: any = environment;

  actualDarshanType = '';
  view: CalendarView = CalendarView.Month;
  viewCalendar = true;
  selectedDate = moment(new Date()).format('YYYY-MM-DD');
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh = new Subject<void>();
  activeDayIsOpen: boolean = false;
  form: FormGroup;
  allDarshanTypes = [];
  allStates = [];
  allCities = [];
  minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate = ''
  timeDisable = false;
  formMode = 'Add';
  visitors_basic_details: any;
  dateErrorMsg = '';
  visitor_type = [];
  id_proof_type_list = []
  user_roles:any;
  darshan_type_id = '';
  readDarshanType = '';
  viewCalanderStatus =  true;
  registrationDate = '';
  user_picture_file_name = '';
  id_proof_name = '';
  show_reference_limit_msg = '';
  checkLengthStatus = false;
  timeSlotList:any = [];
  calendarEndDate = moment(new Date()).format('YYYY-MM-DD');
  remainingCountData:any;
  restrictedDates:any = [];
  selectedDarshanObj:any;

  fileData:any = [
    { id: 0,
      user_picture_file_name: '',
      id_proof_name: '',
      imageloading: false,
      idProofloading: false
    }
  ];

  myFilter = (d:any): boolean => {
    this.timeDisable = false;
    const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type_id == this.darshan_type_id && moment(moment(res.date).format('DD-MM-YYYY'), 'DD-MM-YYYY').isSame(d)));
    // return true

    if (isrestricted) {
      return false;
    } else {
      return true;
    }
  }

  allRelations = [];
  recaptchaVerified = false;
  modalResultSelection = true;
  server_datetime: any;
  bhasmaartiCountInterval:any;
  bhasmaartiCalenderBlocked = false;
  nextThreeMonthsBhasmaartiRequests: any =  [];



  constructor(
    private _formBuilder: FormBuilder,
    private apiService : ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sortListService: SortListService,
    private cd: ChangeDetectorRef,
    private appComponent: AppComponent,
    private modalService: NgbModal

  ) {
    this.form = this._formBuilder.group({
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      mobile_no: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      date_of_visit: ['', [Validators.required]],
      darshan_type_id: ['', [Validators.required]],
      department_id: [''],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      no_of_persons: ['', [Validators.required]],
      // time_of_visit: ['' ? [Validators.required] : []],
      slot_configuration_id: [''],
      id: [''],
      visitors_basic_details: this._formBuilder.array([this.getPersonRow()])
    });
  }



  ngOnInit() {
    this.actualDarshanType = this.darshanType;
    const date = new Date();
    const re = /-/gi;
    const newDarshanType = this.darshanType.replace(re, "_");
    this.readDarshanType = newDarshanType;
    if(newDarshanType == 'bhasma_aarti') {
      if(date.getDate() > 20 ||  (date.getDate() == 20 && date.getHours() >= 8)){
        date.setMonth(date.getMonth()+1, 1);
        this.viewDate = date;
        this.minDate = moment(date).format('YYYY-MM-DD');
        let maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth()+ 2, 0);
        this.maxDate = moment(maxDate).format('YYYY-MM-DD');
      } else {
    date.setDate(1);
    this.viewDate = date;
    this.minDate = moment(date).format('YYYY-MM-DD');
    let maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth()+ 1, 0);
        this.maxDate = moment(maxDate).format('YYYY-MM-DD');
      }
    } else {
      date.setDate( date.getDate());
      this.minDate = moment(date).format('YYYY-MM-DD');
    }
    this.getAllStates();
    this.getIdProofTypesList();
    this.getAllRelationsList();
    this.getRestrictedDates();
    this.getDarshanType();

    console.log(this.actualDarshanType);
    for(let i=0; i<=2;i++){
      this.nextThreeMonthsBhasmaartiRequests.push(moment(new Date(new Date().getFullYear(), new Date().getMonth() + (2+i), 1)));
    }
  }

  getDarshanType() {
    this.apiService.getPublicDataFromServer('/darshan_types').subscribe(
      (res:any) => {
        const darshan_type =  this.readDarshanType == 'paid_darshan' ? 'general_darshan' : this.readDarshanType == 'bhasma_aarti' ? 'general_bhasmaarti' : this.readDarshanType == 'sheegra_darshan' ? 'Sheeghra Darshan' : this.readDarshanType;
        const data:any = res['data'].find((res:any) => res.slug == darshan_type);
        this.selectedDarshanObj = data;
      },
      err => {
        console.log(err);
      }
    );
  }

  getControls() {
    return (this.form.get('visitors_basic_details') as FormArray).controls;
  }
  getControlsDetail(i:number) {
    return (this.form.get('visitors_basic_details') as FormArray).controls[i];
  }

  getLimitRemainingCounts() {
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const endDate = moment(new Date(this.calendarEndDate)).format('YYYY-MM-DD');
    this.apiService.getPublicDataFromServer(`/bhasmaarti_counts?date=${currentDate}&end_date=${endDate}`).subscribe(
      (res:any) => {
        this.remainingCountData = res.data;
        this.server_datetime = res.server_datetime;
        if(this.darshanType == 'bhasma-aarti'){
         this.bhasmaartiCountInterval =  setInterval(()=>{
            const duration = moment().diff(moment(this.server_datetime));
            if(Math.abs(duration) > 3000000000){
              this.bhasmaartiCalenderBlocked = true;
            }       
           },2000);     
          }
      });
  }

  calendarDate(event:any) {
    this.calendarEndDate = event.period.end;
    this.getLimitRemainingCounts();
  }

  getNextMonth(_date: Date){
    let newDate = new Date(_date);
    newDate.setMonth(newDate.getMonth()+1)
       return newDate;
  }

  checkRemainingLimit(date:any) {
    const activeDate =  moment(date).format('YYYY-MM-DD');
    const currentDate  =  moment(new Date()).format('YYYY-MM-DD');
    if(this.remainingCountData && this.remainingCountData.length && this.darshanType == 'bhasma-aarti' && activeDate !== currentDate){
      const isExist = this.remainingCountData.find((res:any) =>  moment(res.date).format('YYYY-MM-DD') == activeDate);
      const darshan_type =  this.readDarshanType == 'paid_darshan' ? 'general_darshan' : this.readDarshanType == 'bhasma_aarti' ? 'general_bhasmaarti' : this.readDarshanType == 'sheegra_darshan' ? 'Sheeghra Darshan' : this.readDarshanType;
      const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type.slug == darshan_type && moment(res.date).format('YYYY-MM-DD') == activeDate));
      if(isExist && !isrestricted){
        return isExist ? `Remaining Slots - ${isExist.remaining_limit}` : '';
      } else {
          return '';
      }
    } else {
      return '';
    }
  }

  resolvedRecaptcha(captchaResponse: string, captchaRef: any) {
    this.recaptchaVerified = false;
    this.apiService.postPublicDataToServer('/verify_recaptcha',{token : captchaResponse}).subscribe(
      (res:any) => {
       if(res.success){
        this.recaptchaVerified = true;
       }else {
        captchaRef.reset();
       }
      },
      err => {
        console.log(err);
        captchaRef.reset();
      }
    );
  }

  onPickerOpen(e:any){
    const darshan_type =  this.readDarshanType == 'paid_darshan' ? 'general_darshan' : this.readDarshanType == 'bhasma_aarti' ? 'general_bhasmaarti' : this.readDarshanType == 'sheegra_darshan' ? 'Sheeghra Darshan' : this.readDarshanType;

    const filteredDates =  this.restrictedDates.filter((res:any) => (res.darshan_type.slug == darshan_type));


    for (let i = 0; i < filteredDates.length; i++) {
      let date  = moment(filteredDates[i].date).format('MMMM D, YYYY');
      let classname:any = document.querySelector(`[aria-label="${date}"].owl-dt-calendar-cell-disabled`);

      if(classname) {
        let element = classname.getElementsByTagName('span');


        let span = document.createElement('span');
        let content = document.createTextNode('Blocked');
        span.appendChild(content);

        if(element[0].querySelector('span')) {
          element[0].querySelector('span').remove();
        }
        element[0].appendChild(span);
      }
    }
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.selectedDate = moment(date).format('DD-MM-YYYY');
    const currentDate = new Date();
    currentDate.setDate( currentDate.getDate() + 1);
    const time = new Date().getHours();
    console.log(moment(currentDate).format('DD-MM-YYYY') != this.selectedDate)
    console.log((time >= 8 && time <= 20) ||  moment(currentDate).format('DD-MM-YYYY') != this.selectedDate)
      if(this.darshanType == 'bhasma-aarti' && (moment(new Date).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY') || new Date(date) < new Date())){
        this.appComponent.showMessageAlert('Please Select Future Date for Bhasmarti', 'error');
      } else {
        if(((time >= 8 && time <= 20)) || this.darshanType != 'bhasma-aarti'){
          clearInterval(this.bhasmaartiCountInterval);
          this.checkRestrictedDates(date);

        } else {
          this.appComponent.showMessageAlert('Booking allowed between 8 AM to 9 PM for This Date', 'error');
        }
      }
    document.body.addEventListener('click', () => setTimeout(() => {                           // <<<---using ()=> syntax
      this.onPickerOpen('');
    }, 1000), true);

  }


  showRestrictedDateMessage(date:any){
    const activeDate =  moment(date).format('YYYY-MM-DD');
    const currentDate  =  moment(new Date()).format('YYYY-MM-DD');
    if(activeDate !== this.minDate){
      const darshan_type =  this.readDarshanType == 'paid_darshan' ? 'general_darshan' : this.readDarshanType == 'bhasma_aarti' ? 'general_bhasmaarti' : this.readDarshanType == 'sheegra_darshan' ? 'Sheeghra Darshan' : this.readDarshanType;
      const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type.slug == darshan_type && moment(res.date).format('YYYY-MM-DD') == activeDate));
      const maxLength = this.selectedDarshanObj ? this.selectedDarshanObj.max_allowed_days : '15';
      const max = new Date();
      max.setDate( max.getDate() + Number(maxLength));
      if(isrestricted){
        return this.darshanType == 'bhasma-aarti' ? 'The date is blocked for Bhasm Aarti registration.' : 'The date is blocked for Paid Darshan Ticket.'
      }else {
        return ''
      }
    } else {
      return '';
    }
  }
  showNotOpenRestrictedDateMessage(date:any){
    const activeDate =  moment(date).format('YYYY-MM-DD');
    const currentDate  =  moment(new Date()).format('YYYY-MM-DD');
    if(activeDate >= this.minDate){
      const maxLength = this.selectedDarshanObj ? this.selectedDarshanObj.max_allowed_days : '15';
      let max = new Date();
      if(this.darshanType == 'bhasma-aarti'){
         max = new Date(this.maxDate);
      } else {
        max.setDate( max.getDate() + Number(maxLength));
      }

      if(new Date(max) < new Date(date) || new Date(date) <= new Date()){
        return this.darshanType == 'bhasma-aarti' ? 'The date is not open for Bhasm Aarti registration.' : 'The date is not open for Paid Darshan Ticket.'
      }else {
        return ''
      }
    } else {
      return '';
    }
  }

  checkRestrictedDates(date:any) {
    const darshan_type =  this.readDarshanType == 'paid_darshan' ? 'general_darshan' : this.readDarshanType == 'bhasma_aarti' ? 'general_bhasmaarti' : this.readDarshanType == 'sheegra_darshan' ? 'Sheeghra Darshan' : this.readDarshanType;
    const isrestricted =  this.restrictedDates.find((res:any) => (res.darshan_type.slug == darshan_type && moment(res.date).format('DD-MM-YYYY') == this.selectedDate));
    const maxLength = this.selectedDarshanObj ? this.selectedDarshanObj.max_allowed_days : '15';
    let max = new Date();
    if(this.readDarshanType == 'bhasma_aarti'){
        max = new Date(this.maxDate);
    } else {
      max.setDate( max.getDate() + Number(maxLength));
      this.maxDate =  moment(max).format('YYYY-MM-DD');
    }
    
    if(new Date(max) > new Date(date)){
      const isExist = this.remainingCountData.find((res:any) =>  moment(res.date).format('YYYY-MM-DD') ==  moment(date).format('YYYY-MM-DD'));
      if(!isrestricted){
        if (new Date(date) > new Date()  || moment(new Date).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY')) {
          if(this.readDarshanType != 'bhasma_aarti' || (isExist && Number(isExist.remaining_limit) > 0)){
            this.getRegistrationDate(this.selectedDate);
          } else {
            const msg = `The selected date remaining slots is ${isExist.remaining_limit} please select any other date.`;
            this.appComponent.showMessageAlert(msg, 'error');
          }
            // this.getRegistrationDate(this.selectedDate);
        } else {
          this.appComponent.showMessageAlert('Please Select Today or Future Date', 'error');
        }
      } else {
        const msg = this.darshanType == 'bhasma-aarti' ? 'The selected date is blocked for Bhasama Aarti registration.' : 'The selected date is blocked for Paid Darshan Ticket.'
        this.appComponent.showMessageAlert(msg, 'error');
      }
    }else {
      const msg = this.darshanType == 'bhasma-aarti' ? 'The date is not open for Bhasm Aarti registration.' : 'The date is not open for Paid Darshan Ticket.'
      this.appComponent.showMessageAlert(msg, 'error');
    }

  }



  getRestrictedDates() {
    this.apiService.getPublicDataFromServer('/restricted_dates').subscribe(
      (res:any) => {
        this.restrictedDates = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }



  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  onClickCapturePhoto(index:any,key:any,controlName:string) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[index]['controls'];

    let document_id = child_cntrl[controlName];
    const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
      {
        centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
        backdrop: 'static', keyboard: false,
      });
    const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
    capturePhotoPopoverComponent.data = null;
    capturePhotoPopoverComponent.headerName = key == 'user_picture_file_name' ? 'Capture Photo' : 'Capture ID Proof Photo';


    modalRef.result.then((result:any) => {
      if (result) {
        this.apiService.getBlobFileFromServer(result).subscribe(res => {
          let blob:any = new Blob([res], { type: res.type.toString() });
          const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
          let file_name = controlArray.controls[index].get('name').value;
          const imageFile = new File([blob], `${file_name}-image`, { type: 'image/png' });
          const obj = {
            id: index,
            user_picture_file_name: '',
            id_proof_name: '',
            imageloading: key != 'id_proof_name' ? true : false,
            idProofloading: key == 'id_proof_name' ? true : false
          }
          const isExits =  this.fileData.find((res:any)  =>  res.id == index);
          if(isExits) {
            // isExits.user_picture_file_name = '';
            isExits.imageloading = key != 'id_proof_name' ? true : false;
            isExits.idProofloading = key == 'id_proof_name' ? true : false;
          } else {
            this.fileData.push(obj);

          }
          this.postDocumentToServer(imageFile, document_id,key,index);
        });

      }
    });
  }


  createForm(data?:any) {
    const date = new Date();
    let futureTime:any = new Date(date.getTime() + 15*60000);
    futureTime = moment(futureTime, 'hh:mm').format();

    this.form = this._formBuilder.group({
      first_name: [data && data.first_name ? data.first_name : '', [Validators.required,this.removeSpaces]],
      last_name: [data && data.last_name ? data.last_name : '', [Validators.required,this.removeSpaces]],
      mobile_no: [data && data.mobile_no ? data.mobile_no : '', [Validators.required, Validators.pattern('[5-9]\\d{9}')]],
      date_of_visit: [data && data.date_of_visit ? data.date_of_visit : '', [Validators.required]],
      darshan_type_id: [this.darshan_type_id ? this.darshan_type_id : '', [Validators.required]],
      department_id: [data ? data.department_id : ''],
      state_id: [data && data.state_id ? data.state_id: '', this.actualDarshanType !=  'sheegra-darshan' ? [Validators.required] : ''],
      city_id: [data && data.city_id ? data.city_id: '', this.actualDarshanType !=  'sheegra-darshan' ? [Validators.required] : ''],
      no_of_persons: [data && data.no_of_persons ? data.no_of_persons: '1', [Validators.required]],
      // time_of_visit: [futureTime],
      slot_configuration_id: ['', this.darshanType !=  'general_bhasmaarti' ? [Validators.required] : []],
      id: [data && data.id ? data.id: ''],
      visitors_basic_details: this._formBuilder.array([this.getPersonRow()])
    });
    this.getAllTimeSlot();
  }


  getPersonRow() {
    return this._formBuilder.group({
      name: ['', [Validators.required]],
      gender: ['', Validators.required],
      relationship_id: [9, Validators.required],
      id_proof_type_id : ['', Validators.required],
      id_proof_number  : ['', [Validators.required,this.removeSpaces]],
      image_url: ['',[Validators.required]],
      id_proof_image_url: ['', [Validators.required]]
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }


  onChangeName() {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      controlArray.controls[0].get('name').setValue(`${this.form.value.first_name} ${this.form.value.last_name}`);
    }
  }
  // onPickerOpen(e:any){
  //   var classname = document.getElementsByClassName("owl-dt-calendar-cell-content");
  //
  //
  //   for (var i = 0; i < classname.length; i++) {
  //     classname[i].addEventListener('click', (el) => {
  //       var attribute = el;
  //       alert(attribute);
  //     }, false);
  //   }
  // }

  onIdProofTypeChange(index:any) {
    this.checkLengthStatus = false;

    let aadharCardRgx = '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$';
    let drivingLicenseRgx = '^([A-Z]{2})(\\d{2}|\\d{3})[a-zA-Z]{0,1}-(\\d{4})-(\\d{7})$';
    let electionCommissionIDRgx = '^([a-zA-Z]){3}([0-9]){7}?$';
    let passportRgx = '^[A-Z][0-9]{8}$';


    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      controlArray.controls[index].get('id_proof_number').setValue('');
      if(controlArray.controls[index].get('id_proof_type_id').value == 1){
        this.checkLengthStatus = true;
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(aadharCardRgx)]);
      }
      if(controlArray.controls[index].get('id_proof_type_id').value == 2){
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(passportRgx)]);
      }
      if(controlArray.controls[index].get('id_proof_type_id').value == 3){
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(drivingLicenseRgx)]);
      }
      if(controlArray.controls[index].get('id_proof_type_id').value == 4){
        controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(electionCommissionIDRgx)]);
      }
      controlArray.controls[index].get('id_proof_number').updateValueAndValidity();

    }
  }

  getRegistrationDate(event:any) {
    clearInterval(this.bhasmaartiCountInterval);
    this.viewCalendar = false;
    this.registrationDate = event;
    const darshanType = this.darshanType;
    const re = /-/gi;
    const newDarshanType = darshanType.replace(re, "_");
    this.getAllDarshanTypes(newDarshanType);
    this.viewCalanderStatus = false;
  }


  getAllDarshanTypes(type:any) {
    this.appComponent.showLoader();
    this.apiService.getPublicDataFromServer('/darshan_types').subscribe(
      (res:any) => {
        this.apiService.getPublicDataFromServer('/departments').subscribe(
          (departments:any) => {
            const allDepartments = departments.data;
            this.allDarshanTypes = res.data;
            const darshan_type =  type == 'paid_darshan' ? 'general_darshan' : type == 'bhasma_aarti' ? 'general_bhasmaarti' : type == 'sheegra_darshan' ? 'sheegra_darshan' : type;
            this.darshanType = darshan_type;
            const data:any = this.allDarshanTypes.find((res:any) => res.slug == darshan_type);
            this.selectedDarshanObj = data;
            this.darshan_type_id = data ? data.id : '';

            const department_name = type == 'general_darshan' ?  'general_darshan_free' : type == 'paid_darshan' ? 'general_darshan_paid' : type == 'sheegra_darshan' ? 'general_darshan_paid' : '';
            const department = allDepartments.find((res:any) => res.slug == department_name);

            this.activatedRoute.queryParams.subscribe(params => {
                let date_of_visit = params.date ? params.date : this.registrationDate;
                if(date_of_visit) {
                  this.viewCalanderStatus = false;
                }
                this.createForm({
                  date_of_visit: moment(date_of_visit ? date_of_visit : new Date(),'DD-MM-YYYY').format(),
                  department_id: department ? department.id : '',
                  darshan_type: darshan_type
                });
                this.check_Reference_Limit();
            });
            this.appComponent.hideLoader();
          });
      },
      err => {
        console.log(err);
      }
    );
  }


  check_Reference_Limit() {
    if(this.form.value.darshan_type_id && (this.actualDarshanType == 'bhasma-aarti' || this.form.value.slot_configuration_id)) {
      const data: any = {
        "darshan_type_id": this.form.value.darshan_type_id,
        "date_of_visit": moment(this.form.value.date_of_visit).format('YYYY-MM-DD'),
        "no_of_persons": this.form.value.no_of_persons,
        "department_id": this.form.value.department_id,
        "slot_configuration_id": this.form.value.slot_configuration_id,
        // "time_of_visit": this.form.value.time_of_visit ? moment(this.form.value.time_of_visit).format('hh:mm') : ''
        }
      this.apiService.postPublicDataToServer('/check_limit',data).subscribe(
        (res:any) => {
          this.show_reference_limit_msg = res.message == 'Reference limit available.' ? '' : res.message;
          // this.show_reference_limit_msg = res.message == 'Darshan limit available.' ? '' : res.message;
        },
        err => {
          console.log(err);
        }
      );
    }
  }



  getAllVisitorTypes() {
    this.apiService.getPublicDataFromServer('/visitor_types').subscribe(
      (res:any) => {
        this.visitor_type = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllTimeSlot() {
    this.form.controls['slot_configuration_id'].setValue('');
    const date = moment(this.form.value.date_of_visit).format('YYYY/MM/DD');
    this.apiService.getPublicDataFromServer(`/general_darshan_slots?date=${date}`).subscribe(
      (res:any) => {
        this.timeSlotList = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllStates() {
    this.apiService.getPublicDataFromServer('/states').subscribe(
      (res:any) => {
        // this.allStates = res['data'];
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allStates = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllCities(_id:any) {
    this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.allCities = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getIdProofTypesList() {
    this.apiService.getPublicDataFromServer('/id_proof_types').subscribe(
      (res:any) => {
        this.sortListService.sortList(res['data'],'name').then(res => {
          this.id_proof_type_list = res;
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  getAllRelationsList() {
    this.apiService.getPublicDataFromServer('/relations').subscribe(
      (res:any) => {
        this.allRelations = res['data'];
      },
      err => {
        console.log(err);
      }
    );
  }

  saveData() {
    const formObj = this.form.getRawValue();

    formObj.date_of_visit = moment(formObj.date_of_visit).format('YYYY-MM-DD');
    // if(formObj.time_of_visit){
    //   formObj.time_of_visit = moment(formObj.time_of_visit).format('HH:mm');
    // }

    if(this.formMode == 'Add') {
      this.appComponent.showLoader();
      this.apiService.postPublicDataToServer('/visitors', formObj).subscribe(
        (res:any) => {
          this.appComponent.showMessageAlert(res['message'], 'success');
          this.user_picture_file_name = '';
          this.id_proof_name = '';
          if(res.data && res.data.payment_status == 'Paid' && res.e_pass_url) {
            window.open(res.e_pass_url);
            // this.close();
            // this.createForm();
            this.viewCalendar = true;
            // window.location.reload();
          } else {
            this.router.navigate(['/payment', res['data']['id']]);
          }
          setTimeout(() => {
            this.appComponent.hideLoader();
          },200);
          this.fileData = [];
        },
        err => {
          this.appComponent.hideLoader();
          this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
          console.log(err);
        }
      );
    }
  }

  onChangeTime() {
    this.check_Reference_Limit();
  }

  onChangeDate(content: any) {
    if(!this.modalResultSelection){
      this.modalResultSelection = true;
      return;
    }

  //  const data:any = this.allDarshanTypes.find((res:any) => res.slug == 'general_bhasmaarti');
  //   const date = moment(this.form.value.date_of_visit).format('DD-MM-YYYY');
  //   // const currentDate = moment(new Date()).format('YYYY/MM/DD');
  //   const blockedDate =  moment(new Date('2022-03-19')).format('DD-MM-YYYY');
  //   const blockedDate2 =  moment(new Date('2022-03-22')).format('DD-MM-YYYY');
  //   if(blockedDate == date || blockedDate2 == date) {
  //     // this.dateErrorMsg = "YouCantSelectTodayDateForBhasmaaarti";
  //     this.dateErrorMsg = data.id == this.form.value.darshan_type_id ? 'The selected date is blocked for Bhasama Aarti registration.' : 'The selected date is blocked for Paid Darshan Ticket.'
  //   } else {
  //     this.dateErrorMsg = '';
  //   }
  if(this.darshanType == 'general_bhasmaarti'){
    this.openConfirmationModal(content);
  } else {
    this.check_Reference_Limit();
    this.getAllTimeSlot();
  }
  }

  openConfirmationModal(content: TemplateRef<any>) {
		this.modalService.open(content, 
      {
         windowClass: 'my-class', 
        backdrop: true
      }
    ).result.then(
			(result) => {
        if(result == 'Yes'){
          this.registrationDate = moment(this.form.getRawValue().date_of_visit).format('DD-MM-YYYY')
            this.createForm({
              date_of_visit: moment(this.form.getRawValue().date_of_visit).format(),
              darshan_type: "general_bhasmaarti"
            });
          this.ngOnInit();
          this.check_Reference_Limit();
        } else {
          this.modalResultSelection = false;
          this.form.controls.date_of_visit.setValue(moment(this.registrationDate,'DD-MM-YYYY').format());

        }
			},
      (reason) => {
        if(reason == 0){
          this.modalResultSelection = false;
          this.form.controls.date_of_visit.setValue(moment(this.registrationDate,'DD-MM-YYYY').format());

        }
			},
		);
	}

  onPersonCountChange(){

    let visitors_basic_details : any  = <FormArray>this.form.controls['visitors_basic_details'];
    if(this.form.value.no_of_persons >= 1) {
      if(visitors_basic_details.length < this.form.value.no_of_persons) {
        let persons = this.form.value.no_of_persons - visitors_basic_details.length;
        for (let i = 0; i < persons; i++) {
          visitors_basic_details.push(this._formBuilder.group({
            name: ['', [Validators.required]],
            gender: ['', Validators.required],
            relationship_id: ['', this.actualDarshanType != 'sheegra-darshan' ? Validators.required : ''],
            id_proof_type_id: ['', this.actualDarshanType != 'sheegra-darshan' ? Validators.required : ''],
            id_proof_number: ['', this.actualDarshanType != 'sheegra-darshan' ? [Validators.required,this.removeSpaces] : ''],
            image_url: ['', this.actualDarshanType != 'sheegra-darshan' ? Validators.required : ''],
            id_proof_image_url: ['', this.actualDarshanType != 'sheegra-darshan' ? Validators.required : '']
          }));
        }
      } else {
        let persons = visitors_basic_details.length;
        for (let i = persons - 1; i >  this.form.value.no_of_persons - 1; i--) {
          visitors_basic_details.removeAt(i);
        }
      }
    }
    

    this.check_Reference_Limit();
  }

  onStateChange(_value:any){
    this.getAllCities(_value.target.value);
  }

  onChangePeopleProofNumber(i:any) {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('id_proof_number') && controlArray.controls[i].get('id_proof_number').value && !controlArray.controls[i].get('id_proof_number').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('id_proof_number').setValue('');
    }

  }

  createSpaceProofNumber(i:any){
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if(controlArray.controls && controlArray.controls.length) {
      if(controlArray.controls[i].get('id_proof_type_id').value == 1) {
        let id_proof_number =  controlArray.controls[i].get('id_proof_number').value;
        id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        controlArray.controls[i].get('id_proof_number').setValue(id_proof_number);
      }
    }
  }



  onChangePeopleName(i:any) {
    const controlArray:any = <FormArray> this.form.get('visitors_basic_details');
    if (controlArray.controls[i].get('name') && controlArray.controls[i].get('name').value && !controlArray.controls[i].get('name').value.replace(/\s/g, '').length) {
      controlArray.controls[i].get('name').setValue('');
    }
  }

  public remove(i: number) {
    this.form.controls['no_of_persons'].setValue(this.form.value.no_of_persons - 1);
    const contrls = <FormArray>this.form.controls['visitors_basic_details'];
      contrls.removeAt(i);
  }

  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFileChange(event:any, i:any) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[i]['controls'];

    let document_id = child_cntrl['image_url'];

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const obj = {
        id: i,
        user_picture_file_name: '',
        id_proof_name: '',
        imageloading: true,
        idProofloading: false
      }
      const isExits =  this.fileData.find((res:any)  =>  res.id == i);
      if(isExits) {
        isExits.user_picture_file_name = '';
        isExits.imageloading = true;
      } else {
        this.fileData.push(obj);

      }
      reader.readAsDataURL(file);
      this.postDocumentToServer(event.target.files[0], document_id,'user_picture_file_name', i);

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  checkFileData(i:any,key:any) {
    const isExist = this.fileData.find((res:any) => res.id == i);
    return isExist && isExist[key] ? isExist[key] : '';
  }

  getIdProofFileDetails(event:any, i:any) {
    const _control:any = <FormArray>this.form.controls['visitors_basic_details'];
    const child_cntrl:any = <FormArray>_control.controls[i]['controls'];

    let document_id = child_cntrl['id_proof_image_url'];
    // let entity_tp_id = this.form.controls['entity_id'].value;

    let reader = new FileReader();

    if (event.target['files'] && event.target['files'].length) {
      const [file] = event.target['files'];
      reader.readAsDataURL(file);
      const obj = {
        id: i,
        user_picture_file_name: '',
        id_proof_name: '',
        imageloading: false,
        idProofloading: true
      }
      const isExits =  this.fileData.find((res:any)  =>  res.id == i);
      if(isExits) {
        isExits.id_proof_name = '';
        isExits.idProofloading = true;
      } else {
        this.fileData.push(obj);

      }

      this.postDocumentToServer(event.target['files'][0], document_id,'id_proof_name',i);

      reader.onload = () => {
        this.cd.markForCheck();
      };
    }
  }

  postDocumentToServer(file_data:any, document_id:any,key:any,index:any) {
    const formData = new FormData();
    formData.append('file', file_data);


    this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
      let responsedata :  any = res;
        if(responsedata.data){
          document_id.setValue(responsedata.data);
        }
        this.fileData[index][key] = responsedata.data;
        this.fileData[index].imageloading =  false;
        this.fileData[index].idProofloading =  false;

    }, (error) => {
      this.fileData[index].imageloading =  false;
      this.fileData[index].idProofloading =  false;
        this.appComponent.hideLoader();
        this.appComponent.showMessageAlert(error.error.error[0].message, 'error');
        console.log(error);
    });
  }

  // close() {
  //   const darshanType = this.activatedRoute.snapshot.params['darshanType'];
  //   this.router.navigate([`/registration/${darshanType}`]);
  // }

  ngOnDestroy(){
    clearInterval(this.bhasmaartiCountInterval);
  }

}
