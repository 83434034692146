import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {filter} from 'rxjs/operators';

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {
  loader:any;

  message = '';
  alert_type = 'success';
  isAlertShow = false;
  urlName:any = '';
  constructor(
    private breadcrumbService: BreadcrumbService,
    private changeDetector : ChangeDetectorRef,
    private router : Router,
    private location: Location,
    public translate: TranslateService,
    public titleService: Title
    ) {
      translate.addLangs(['en', 'hi']);
      translate.setDefaultLang('en');
  }
  ngOnInit(): void {
    this.setUpAnalytics();
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });
  }
  onActivate(_event: any){
    window.scroll(0,0);
  }
  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'Shree Mahakaleshwar';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${title}${routeTitle}`;
  }

  private titlesToString(titles: any[]) {
    return titles.reduce((prev: any, curr: { displayName: any; }) => {
      return `${prev} | ${curr.displayName}`;
    }, '');
  }

  ngAfterViewChecked(){
    this.changeDetector.detectChanges();
  }


    showLoader(){
      this.loader =  true;
    }

    hideLoader(){
      this.loader = false;
    }


    showMessageAlert(_message:any, _type:any){
    this.message = _message;
    this.alert_type = _type;
    this.isAlertShow = true;
      setTimeout(() => this.isAlertShow = false, 3000);

    }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        gtag('config', 'G-TELVZ3Q9EQ',
          {
            page_path: event.urlAfterRedirects
          }
        );
      });
  }

}
