import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/service/api.service';
import { SortListService } from 'src/app/service/sort-list.service';
import { CapturePhotoPopoverComponent } from '../../shared/capture-photo-popover/capture-photo-popover.component';

@Component({
  selector: 'app-general-bhasmaarti-registration',
  templateUrl: './general-bhasmaarti-registration.component.html',
  styleUrls: ['./general-bhasmaarti-registration.component.css']
})
export class GeneralBhasmaartiRegistrationComponent implements OnInit {

  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/logo-2.png";
  ftbg = "assets/images/footer-1.jpg";

  form!: FormGroup;
  mobileNoForm!: FormGroup;
  verifyOtpform!: FormGroup;
  generalBhasmaartiform!: FormGroup;

  fileData:any = [
    { id: 0,
      user_picture_file_name: '',
      id_proof_name: '',
      imageloading: false,
      idProofloading: false
    }
  ];

  allStates = [];
  allCities = [];
  id_proof_type_list = [];
  checkLengthStatus = false;
  no_of_people = '';


  constructor(
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private sortListService: SortListService
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.CreateForm({
        qr_code: params.qr_code
      });
    });
  }

  ngOnInit(): void {
    this.getAllStates();
    this.getIdProofTypesList();
  }

  CreateForm(data?: any) {
    this.form = this._formBuilder.group({
      no_of_people: ['', [Validators.required]],
      qr_code: [data.qr_code || '', [Validators.required]]
    });
  }

  CreateMobileNoForm(data?: any) {
    this.mobileNoForm = this._formBuilder.group({
      mobile_no: ['', [Validators.required]],
      session_id: [data.session_id || '', [Validators.required]]
    });
  }

  CreateVerifyOtpForm(data?: any) {
    this.verifyOtpform = this._formBuilder.group({
      session_id: [data.session_id || '', [Validators.required]],
      otp: ['', [Validators.required]]
    });
  }

  CreateBhasmaartiForm(data?: any) {
    this.generalBhasmaartiform = this._formBuilder.group({
      session_id: [data.session_id || '', [Validators.required]],
      first_name: ['', [Validators.required,this.removeSpaces]],
      last_name: ['', [Validators.required,this.removeSpaces]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      visitors_basic_details: this._formBuilder.array(this.getPersonRow(this.no_of_people))    });
  }

  getPersonRow(persons: any) {

    let visitors_basic_details = [];
    for (let i = 0; i < persons; i++) {
      visitors_basic_details.push(this._formBuilder.group({
        name: ['', [Validators.required]],
      gender: ['', Validators.required],
      id_proof_type_id : ['', Validators.required],
      id_proof_number  : ['', [Validators.required,this.removeSpaces]],
      image_url: ['',[Validators.required]],
      id_proof_image_url: ['', [Validators.required]]
      }));
    }
    return visitors_basic_details;
  }

    getControls() {
      return (this.generalBhasmaartiform.get('visitors_basic_details') as FormArray).controls;
    }

    getControlsDetail(i:number) {
      return (this.generalBhasmaartiform.get('visitors_basic_details') as FormArray).controls[i];
    }

    getAllStates() {
      this.apiService.getPublicDataFromServer('/states').subscribe(
        (res:any) => {
          // this.allStates = res['data'];
          this.sortListService.sortList(res['data'],'name').then(res => {
            this.allStates = res;
          });
        },
        err => {
          console.log(err);
        }
      );
    }
  
    getAllCities(_id:any) {
      this.apiService.getPublicDataFromServer('/cities/?state_id=' + _id).subscribe(
        (res:any) => {
          this.sortListService.sortList(res['data'],'name').then(res => {
            this.allCities = res;
          });
        },
        err => {
          console.log(err);
        }
      );
    }

    getIdProofTypesList() {
      this.apiService.getPublicDataFromServer('/id_proof_types').subscribe(
        (res:any) => {
          this.sortListService.sortList(res['data'],'name').then(res => {
            this.id_proof_type_list = res;
          });
        },
        err => {
          console.log(err);
        }
      );
    }

  saveData() {
      const formObj = this.form.getRawValue();  
  
        this.appComponent.showLoader();
        this.apiService.postPublicDataToServer('/general_bhasmaarti/start', formObj).subscribe(
          (res:any) => {
            this.appComponent.showMessageAlert(res['message'], 'success');
            this.no_of_people = formObj.no_of_people;
            this.CreateMobileNoForm(res.data);
            setTimeout(() => {
              this.appComponent.hideLoader();
            },200);
        
            },
          err => {
            this.appComponent.hideLoader();
            this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
            console.log(err);
          }
        );
      }

      sendOtp() {
        const formObj = this.mobileNoForm.getRawValue();  
    
          this.appComponent.showLoader();
          this.apiService.postPublicDataToServer('/general_bhasmaarti/send_otp', formObj).subscribe(
            (res:any) => {
              this.appComponent.showMessageAlert(res['message'], 'success');
              this.CreateVerifyOtpForm(formObj);
              setTimeout(() => {
                this.appComponent.hideLoader();
              },200);
          
              },
            err => {
              this.appComponent.hideLoader();
              this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
              console.log(err);
            }
          );
        }

        verifyOtp() {
          const formObj = this.verifyOtpform.getRawValue();  
      
            this.appComponent.showLoader();
            this.apiService.postPublicDataToServer('/general_bhasmaarti/verify_otp', formObj).subscribe(
              (res:any) => {
                this.appComponent.showMessageAlert(res['message'], 'success');
                this.CreateBhasmaartiForm(formObj);
                setTimeout(() => {
                  this.appComponent.hideLoader();
                },200);
            
                },
              err => {
                this.appComponent.hideLoader();
                this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
                console.log(err);
              }
            );
          }

          saveRegistrationData() {
              const formObj = this.generalBhasmaartiform.getRawValue();
          
             
          
                this.appComponent.showLoader();
                this.apiService.postPublicDataToServer('/general_bhasmaarti/complete', formObj).subscribe(
                  (res:any) => {
                    this.appComponent.showMessageAlert(res['message'], 'success');
                    
                    // if(res.data && res.data.payment_status == 'Paid' && res.e_pass_url) {
                    //   window.open(res.e_pass_url);
                    //   // this.close();
                    //   // this.createForm();
                    //   this.viewCalendar = true;
                    //   // window.location.reload();
                    // } else {
                      this.router.navigate(['/general-bhasmaarti-status', res['data']['id']]);
                    // }
                    setTimeout(() => {
                      this.appComponent.hideLoader();
                    },200);
                    this.fileData = [];
                  },
                  err => {
                    this.appComponent.hideLoader();
                    this.appComponent.showMessageAlert(err.error.error[0].message, 'error');
                    console.log(err);
                  }
                );
              
            }

            onFileChange(event:any, i:any) {
              const _control:any = <FormArray>this.generalBhasmaartiform.controls['visitors_basic_details'];
              const child_cntrl:any = <FormArray>_control.controls[i]['controls'];
          
              let document_id = child_cntrl['image_url'];
          
              let reader = new FileReader();
          
              if (event.target.files && event.target.files.length) {
                const [file] = event.target.files;
                const obj = {
                  id: i,
                  user_picture_file_name: '',
                  id_proof_name: '',
                  imageloading: true,
                  idProofloading: false
                }
                const isExits =  this.fileData.find((res:any)  =>  res.id == i);
                if(isExits) {
                  isExits.user_picture_file_name = '';
                  isExits.imageloading = true;
                } else {
                  this.fileData.push(obj);
          
                }
                reader.readAsDataURL(file);
                this.postDocumentToServer(event.target.files[0], document_id,'user_picture_file_name', i);
          
                reader.onload = () => {
                  this.cd.markForCheck();
                };
              }
            }

            onClickCapturePhoto(index:any,key:any,controlName:string) {
                const _control:any = <FormArray>this.generalBhasmaartiform.controls['visitors_basic_details'];
                const child_cntrl:any = <FormArray>_control.controls[index]['controls'];
            
                let document_id = child_cntrl[controlName];
                const modalRef = this.modalService.open(CapturePhotoPopoverComponent,
                  {
                    centered: false, size: 'md', windowClass: 'my-class', scrollable: false,
                    backdrop: 'static', keyboard: false,
                  });
                const capturePhotoPopoverComponent: CapturePhotoPopoverComponent = modalRef.componentInstance;
                capturePhotoPopoverComponent.data = null;
                capturePhotoPopoverComponent.headerName = key == 'user_picture_file_name' ? 'Capture Photo' : 'Capture ID Proof Photo';
            
            
                modalRef.result.then((result:any) => {
                  if (result) {
                    this.apiService.getBlobFileFromServer(result).subscribe(res => {
                      let blob:any = new Blob([res], { type: res.type.toString() });
                      const controlArray:any = <FormArray> this.generalBhasmaartiform.get('visitors_basic_details');
                      let file_name = controlArray.controls[index].get('name').value;
                      const imageFile = new File([blob], `${file_name}-image`, { type: 'image/png' });
                      const obj = {
                        id: index,
                        user_picture_file_name: '',
                        id_proof_name: '',
                        imageloading: key != 'id_proof_name' ? true : false,
                        idProofloading: key == 'id_proof_name' ? true : false
                      }
                      const isExits =  this.fileData.find((res:any)  =>  res.id == index);
                      if(isExits) {
                        // isExits.user_picture_file_name = '';
                        isExits.imageloading = key != 'id_proof_name' ? true : false;
                        isExits.idProofloading = key == 'id_proof_name' ? true : false;
                      } else {
                        this.fileData.push(obj);
            
                      }
                      this.postDocumentToServer(imageFile, document_id,key,index);
                    });
            
                  }
                });
              }
        
          checkFileData(i:any,key:any) {
            const isExist = this.fileData.find((res:any) => res.id == i);
            return isExist && isExist[key] ? isExist[key] : '';
          }
        
      
        
          getIdProofFileDetails(event:any, i:any) {
            const _control:any = <FormArray>this.generalBhasmaartiform.controls['visitors_basic_details'];
            const child_cntrl:any = <FormArray>_control.controls[i]['controls'];
        
            let document_id = child_cntrl['id_proof_image_url'];
            // let entity_tp_id = this.form.controls['entity_id'].value;
        
            let reader = new FileReader();
        
            if (event.target['files'] && event.target['files'].length) {
              const [file] = event.target['files'];
              reader.readAsDataURL(file);
              const obj = {
                id: i,
                user_picture_file_name: '',
                id_proof_name: '',
                imageloading: false,
                idProofloading: true
              }
              const isExits =  this.fileData.find((res:any)  =>  res.id == i);
              if(isExits) {
                isExits.id_proof_name = '';
                isExits.idProofloading = true;
              } else {
                this.fileData.push(obj);
        
              }
        
              this.postDocumentToServer(event.target['files'][0], document_id,'id_proof_name',i);
        
              reader.onload = () => {
                this.cd.markForCheck();
              };
            }
          }
        
          postDocumentToServer(file_data:any, document_id:any,key:any,index:any) {
            const formData = new FormData();
            formData.append('file', file_data);
        
        
            this.apiService.postPublicDataToServer('/add_image', formData).subscribe(res => {
              let responsedata :  any = res;
                if(responsedata.data){
                  document_id.setValue(responsedata.data);
                }
                this.fileData[index][key] = responsedata.data;
                this.fileData[index].imageloading =  false;
                this.fileData[index].idProofloading =  false;
        
            }, (error) => {
              this.fileData[index].imageloading =  false;
              this.fileData[index].idProofloading =  false;
                this.appComponent.hideLoader();
                this.appComponent.showMessageAlert(error.error.error[0].message, 'error');
                console.log(error);
            });
          }

          keyPressNumbers(event:any) {
            var charCode = (event.which) ? event.which : event.keyCode;
            // Only Numbers 0-9
            if ((charCode < 48 || charCode > 57)) {
              event.preventDefault();
              return false;
            } else {
              return true;
            }
          }

           removeSpaces(control: AbstractControl) {
              if (control && control.value && !control.value.replace(/\s/g, '').length) {
                control.setValue('');
              }
              return null;
            }

            onChangePeopleName(i:any) {
              const controlArray:any = <FormArray> this.generalBhasmaartiform.get('visitors_basic_details');
              if (controlArray.controls[i].get('name') && controlArray.controls[i].get('name').value && !controlArray.controls[i].get('name').value.replace(/\s/g, '').length) {
                controlArray.controls[i].get('name').setValue('');
              }
            }

            onStateChange(_value:any){
              this.getAllCities(_value.target.value);
            }

            onChangeName() {
              const controlArray:any = <FormArray> this.generalBhasmaartiform.get('visitors_basic_details');
              if(controlArray.controls && controlArray.controls.length) {
                controlArray.controls[0].get('name').setValue(`${this.generalBhasmaartiform.value.first_name} ${this.generalBhasmaartiform.value.last_name}`);
              }
            }

            onIdProofTypeChange(index:any) {
              this.checkLengthStatus = false;
          
              let aadharCardRgx = '^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$';
              let drivingLicenseRgx = '^([A-Z]{2})(\\d{2}|\\d{3})[a-zA-Z]{0,1}-(\\d{4})-(\\d{7})$';
              let electionCommissionIDRgx = '^([a-zA-Z]){3}([0-9]){7}?$';
              let passportRgx = '^[A-Z][0-9]{8}$';
          
          
              const controlArray:any = <FormArray> this.generalBhasmaartiform.get('visitors_basic_details');
              if(controlArray.controls && controlArray.controls.length) {
                controlArray.controls[index].get('id_proof_number').setValue('');
                if(controlArray.controls[index].get('id_proof_type_id').value == 1){
                  this.checkLengthStatus = true;
                  controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(aadharCardRgx)]);
                }
                if(controlArray.controls[index].get('id_proof_type_id').value == 2){
                  controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(passportRgx)]);
                }
                if(controlArray.controls[index].get('id_proof_type_id').value == 3){
                  controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(drivingLicenseRgx)]);
                }
                if(controlArray.controls[index].get('id_proof_type_id').value == 4){
                  controlArray.controls[index].get('id_proof_number').setValidators([Validators.required, Validators.pattern(electionCommissionIDRgx)]);
                }
                controlArray.controls[index].get('id_proof_number').updateValueAndValidity();
          
              }
            }

            onChangePeopleProofNumber(i:any) {
              const controlArray:any = <FormArray> this.generalBhasmaartiform.get('visitors_basic_details');
              if (controlArray.controls[i].get('id_proof_number') && controlArray.controls[i].get('id_proof_number').value && !controlArray.controls[i].get('id_proof_number').value.replace(/\s/g, '').length) {
                controlArray.controls[i].get('id_proof_number').setValue('');
              }
          
            }
          
            createSpaceProofNumber(i:any){
              const controlArray:any = <FormArray> this.generalBhasmaartiform.get('visitors_basic_details');
              if(controlArray.controls && controlArray.controls.length) {
                if(controlArray.controls[i].get('id_proof_type_id').value == 1) {
                  let id_proof_number =  controlArray.controls[i].get('id_proof_number').value;
                  id_proof_number = id_proof_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                  controlArray.controls[i].get('id_proof_number').setValue(id_proof_number);
                }
              }
            }

}
